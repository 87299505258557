import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import formatDate from '../../../../components/common/formatDate';

interface BreakdownModalProps {
  open: boolean;
  onClose: () => void;
  breakdowns: any[];
}

const BreakdownModal: React.FC<BreakdownModalProps> = ({ open, onClose, breakdowns }) => {
  const headers = [
    'Date',
    'Machine',
    'Part',
    'Shift',
    'Operation',
    'Operator',
    'Start Time',
    'Stop Time',
    'Plan Qty',
    'Actual Qty',
    'Remarks',
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#d1f3c5', color: '#395032' }}>
        Breakdown Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#395032',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: '#f3ddc5',
                      color: '#395032',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      padding: '12px',
                      whiteSpace: 'nowrap',
                      borderRight: '1px solid #e0e0e0',
                      borderBottom: '1px solid #e0e0e0',
                      borderLeft: '1px solid #e0e0e0',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {breakdowns.map((breakdown, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                    '&:hover': { backgroundColor: '#e8f4ff' },
                    transition: 'background-color 0.3s',
                  }}
                >
                  <TableCell sx={{ minWidth: '100px', textAlign:'center' }} >{formatDate(new Date(breakdown.module_date))}</TableCell>
                  <TableCell sx={{ minWidth: '150px', textAlign:'center'  }} >{breakdown.mc_no?.machine_no}</TableCell>
                  <TableCell sx={{ minWidth: '150px', textAlign:'center'   }} >{breakdown.part_id?.part_name}</TableCell>
                  <TableCell sx={{ minWidth: '70px', textAlign:'center'   }} >{breakdown.shift}</TableCell>
                  <TableCell sx={{ minWidth: '200px', textAlign:'center'   }} >{breakdown.operation_name}</TableCell>
                  <TableCell sx={{ minWidth: '200px', textAlign:'center'   }} >{breakdown.operator}</TableCell>
                  <TableCell sx={{ minWidth: '130px', textAlign:'center'   }} >{new Date(breakdown.time_start).toLocaleTimeString()}</TableCell>
                  <TableCell sx={{ minWidth: '130px', textAlign:'center'   }} >{new Date(breakdown.time_stop).toLocaleTimeString()}</TableCell>
                  <TableCell sx={{ minWidth: '100px', textAlign:'center'   }} >{breakdown.plan_qty}</TableCell>
                  <TableCell sx={{ minWidth: '100px', textAlign:'center'   }} >{breakdown.actual_qty}</TableCell>
                  <TableCell sx={{ minWidth: '200px', textAlign:'center'   }} >{breakdown.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default BreakdownModal;