import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import GraphCard from './GraphCard';
import { GraphData } from '../../helpers/types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';

const CarouselContainer = styled(Box)({
    //position: 'relative',
    width: '100%',
    padding: '0 50px',
  });
  
  const CarouselContent = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(450px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  });
  
  
  const NavButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));


interface CarouselProps {
  graphData: GraphData[];
  targets: number[];
  updateTarget: (index: number, newValue: number) => void;
  onAnalyze: (endpoint: string, title: string, target: number) => void;
}

const Carousel: React.FC<CarouselProps> = ({ graphData,targets, updateTarget, onAnalyze }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 6;

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % Math.ceil(graphData.length / cardsPerPage));
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + Math.ceil(graphData.length / cardsPerPage)) % Math.ceil(graphData.length / cardsPerPage));
  };

  const currentCards = graphData.slice(currentPage * cardsPerPage, (currentPage + 1) * cardsPerPage);

  return (
    <CarouselContainer>
      <NavButton onClick={prevPage} sx={{ left: 10 }}>
        <ChevronLeftIcon />
      </NavButton>
      <CarouselContent>
        {currentCards.map((data, index) => (
          <GraphCard 
            key={index} 
            data={data}
            target={targets[index] || 0}
            updateTarget={(newValue:any) => updateTarget(index, newValue)}
            onAnalyze={onAnalyze} 
          />
        ))}
      </CarouselContent>
      <NavButton onClick={nextPage} sx={{ right: 10 }}>
        <ChevronRightIcon />
      </NavButton>
    </CarouselContainer>
  );
};

export default Carousel;