import ExcelJS from 'exceljs';
import axios from 'axios';
import dayjs from 'dayjs';

export const generateExcel = async (date: string,  apiEndpoint: string) => {
  try {
    
    const response = await axios.get(apiEndpoint, {
        params: { date }
    });
    let { data, headers, title } = response.data;

    data = data.map((row: any[]) => {
        // Remove the first element of the row
        row.shift();
        
        if (row.length > 0 && typeof row[0] === 'string') {
            // Replace multiple spaces with a single space
            row[0] = row[0].replace(/\s+/g, ' ').trim();
        }
        return row;
    });
  

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('OEE Report');

    // Increase height of the first row
    worksheet.getRow(1).height = 80;

    const getColumnLetter = (num: number) => {
        let letter = '';
        while (num > 0) {
            let modulo = (num - 1) % 26;
            letter = String.fromCharCode(65 + modulo) + letter;
            num = Math.floor((num - modulo) / 26);
        }
        return letter;
    };  

    // Add header rows
    const totalColumns = headers.length;
    const lastColumnLetter = getColumnLetter(headers.length);
    console.log('last column letter:', lastColumnLetter)

    worksheet.mergeCells(`A1:${lastColumnLetter}1`);
    worksheet.mergeCells(`A2:${lastColumnLetter}2`);
    const headerCell1 = worksheet.getCell('A1');
    const headerCell2 = worksheet.getCell('A2');

    headerCell1.value = 'IDEAL PRECISION COMPONENTS Pvt. Ltd.';
    headerCell1.font = { bold: true, size: 16 };
    headerCell1.alignment = { vertical: 'middle', horizontal: 'center' };

    headerCell2.value = title;
    headerCell2.font = { bold: true, size: 14, color: { argb: 'FFFFFF' } };
    headerCell2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
    headerCell2.alignment = { vertical: 'middle', horizontal: 'center' };

    // Add column headers
    worksheet.addRow(headers);
    const headerRow = worksheet.getRow(3);
    headerRow.font = { bold: true };
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };
    headerRow.eachCell((cell) => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDDDDD' } };
      cell.border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
    });

    // Add data
    data.forEach((row: any[], index: number) => {
      worksheet.addRow(row);
      const dataRow = worksheet.getRow(index + 4);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };

        if (cell.value === null) {
            cell.value = '';
        }
      });
    });

    // Auto-fit columns
    const defaultWidth = 15;
    worksheet.columns.forEach((column, index) => {
        if (index === 0) {
            column.width = defaultWidth * 2;
        } else {
            column.width = defaultWidth;
        }
          
      if (column && typeof column.eachCell === 'function') {
        column.eachCell({ includeEmpty: true }, (cell) => {
          if (cell && cell.alignment) {
            cell.alignment = { ...cell.alignment, wrapText: true };
          }
          cell.border = { top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'} };
        });
      }
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Download file
    const fileName = `${title} ${dayjs(date).format('MMMM_YYYY')}.xlsx`;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error('Error generating Excel:', error);
  }
};