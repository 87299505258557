import React from 'react';
import { GraphData, SingleSeriesGraphData } from '../../helpers/types';
import { chartColors } from '../../styles/graphStyles';
import { ResponsiveContainer, BarChart,LineChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Line, Dot } from 'recharts';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';


const StyledTooltip = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid #ccc',
}));


interface CustomGraphProps {
  data: SingleSeriesGraphData;
  target: number;
}

const CustomGraph: React.FC<CustomGraphProps> = ({ data, target }) => {
  const chartData = data.graphData.map((value, index) => ({
    day: index + 1,
    value: value
  }));

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <Typography variant="body2" fontSize={11}>Day: {label}</Typography>
          <Typography variant="body1" color="primary" fontWeight="bold" fontSize={12.5}>
            Value: {payload[0].value.toFixed(2)}
          </Typography>
        </StyledTooltip>
      );
    }
    return null;
  };

  const CustomDot = (props: any) => {
    const { cx, cy, value } = props;
    const fillColor = value >= target ? '#3f51b5' : '#3f51b5'; // Green above target, Red below target
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={3}
        fill={fillColor}
        stroke="#fff"
        strokeWidth={0}
      />
    );
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={chartData} margin={{ top: 5, right: 15, left: -30, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
        <XAxis 
          dataKey="day" 
          tickMargin={10}
          padding={{ left: 10, right: 10 }}
          stroke="rgba(0,0,0,0.5)" 
          ticks={[1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31]}
          interval="preserveStartEnd"
        />
        <YAxis stroke="rgba(0,0,0,0.5)" />
        <Tooltip content={<CustomTooltip />} />
        <ReferenceLine 
          y={target} 
          stroke="#3f51b5"  // Make target line orange and visible
          strokeDasharray="3 3"
          strokeWidth={2}  // Bold target line
        />
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset={`${target}%`} stopColor="#2196f3" stopOpacity={0.8} />
            <stop offset={`${target}%`} stopColor="#90caf9" stopOpacity={0.8} />
          </linearGradient>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#2196f3" />
            <stop offset="100%" stopColor="#90caf9" />
          </linearGradient>
        </defs>
        <Line 
          dataKey="value" 
          stroke="#8884d8" 
          strokeWidth={2}
          dot={<CustomDot />}
          activeDot={{ r: 5, strokeWidth: 1, fill: '#3f51b5', stroke: '#fff' }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomGraph;