import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { generateExcel } from './OperatorExcelGenerator';

const StyledCard = styled(Box)(({ theme }) => ({
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
    backgroundColor: '#e6f2ff',
    border: '1px solid #9bcbff',
    width: '300px',
  }));
  
  const CardHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#9bcbff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));
  
  const CardContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '12px',
    textTransform: 'none',
    backgroundColor: '#9bcbff',
    color: 'white',
    height: '40px',
    '&:hover': {
      backgroundColor: '#4386cf',
    },
    marginTop: theme.spacing(2),
    width: '100%',
  }));
  
  const StyledPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPopover-paper': {
      borderRadius: '16px',
      overflow: 'hidden',
    },
  }));
  
  interface DownloadOption {
    label: string;
    apiEndpoint: string;
  }
  
  const downloadOptions: DownloadOption[] = [
    { label: 'Man Efficiency', apiEndpoint: '/api/oeeManagementRoute/MonthlyOperatorOEEForExcelDownload' },
    // Add more options here in the future
  ];
  
  const DownloadSelector: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleDownload = async (apiEndpoint: string) => {
      if (selectedDate) {
        await generateExcel(selectedDate.format('ddd, DD MMM YYYY HH:mm:ss [GMT]'), apiEndpoint);
      }
      handleClose();
    };
  
    const open = Boolean(anchorEl);
  
    return (
      <>
        <IconButton onClick={handleClick}>
          <GetAppIcon />
        </IconButton>
        <StyledPopover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <StyledCard>
            <CardHeader>
              <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold' }}>Download Options</Typography>
            </CardHeader>
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Month"
                  value={selectedDate}
                  onChange={(newDate) => setSelectedDate(newDate)}
                  views={['year', 'month']}
                  sx={{
                    width: '100%',
                    marginBottom: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                    },
                  }}
                />
              </LocalizationProvider>
              {downloadOptions.map((option, index) => (
                <StyledButton key={index} onClick={() => handleDownload(option.apiEndpoint)}>
                  {option.label}
                </StyledButton>
              ))}
            </CardContent>
          </StyledCard>
        </StyledPopover>
      </>
    );
  };
  
  export default DownloadSelector;