import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, Grid, CircularProgress } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import dayjs from 'dayjs';

// Import components
import Sidebar from '../components/oeeGraphWithFilters/SideBar'
import GraphCard from '../components/oeeGraphWithFilters/GraphCard';
import StatisticCard from '../components/oeeGraphWithFilters/StatisticCard';
import DashboardHeader from '../components/oeeGraphWithFilters/DashBoardHeader';
import DataTypeSelectorModal from '../components/oeeGraphWithFilters/DataTypeSelectorModal';
import DownloadSelector from '../components/oeeGraphWithFilters/DownloadSelector';

// Import context and types
import { OEEProvider, useOEE } from '../components/oeeGraphWithFilters/OEEContext';
import { SingleSeriesGraphData } from '../helpers/types';

// Import Operator Excel generator
import { generateExcel } from '../components/oeeGraphWithFilters/OperatorExcelGenerator';


const DashboardContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f0f8ff',
  minHeight: '100vh',
}));

const ContentArea = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}));

const OEEAnalysisDashboardContent: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentFilterId, setCurrentFilterId] = useState<string | null>(null);
  const { filters, oeeData, setOeeData, updateFilter } = useOEE();
  const [loading, setLoading] = useState(false);

  //const handleDownload = async () => {
  //  const date = dayjs().format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
  //  await generateExcel(date);
  //};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const results = await Promise.all(filters.map(filter => 
          axios.get(`/api/oeeManagementRoute/overallDayWiseOEE`, {
            params: {
              date: filter.selectedDate.format('YYYY-MM-DD'),
              type: filter.dataType,
              value: filter.selectedValue
            }
          })
        ));
        const newData = results.reduce((acc, result, index) => {
          acc[filters[index].id] = result.data;
          return acc;
        }, {} as { [key: string]: SingleSeriesGraphData });
        setOeeData(newData);
      } catch (error) {
        console.error('Error fetching OEE data:', error);
        // Handle error state here
      } finally {
        setLoading(false);
      }
    };

    if (filters.length > 0) {
      fetchData();
    }
  }, [filters, setOeeData]);

  const getStatisticData = (type: 'max' | 'min' | 'average') => {
    return filters.map((filter, index) => {
      const data = oeeData[filter.id];
      if (!data) return { title: `Graph ${index + 1}`, value: 0 };

      const values = data.graphData.filter((v: number) => v > 0);
      let value = 0;
      switch (type) {
        case 'max':
          value = Math.max(...values);
          break;
        case 'min':
          value = Math.min(...values);
          break;
        case 'average':
          value = values.reduce((a: number, b: number) => a + b, 0) / values.length;
          break;
      }
      return { title: `Graph ${index + 1}`, value };
    });
  };

  const handleDataTypeClick = (id: string) => {
    setCurrentFilterId(id);
    setModalOpen(true);
  };

  const handleDataTypeChange = (type: 'overall' | 'machine' | 'operator') => {
    if (currentFilterId) {
      updateFilter(currentFilterId, { dataType: type, selectedValue: null });
    }
    //setModalOpen(false);
  };

  const handleValueChange = (value: string | null) => {
    if (currentFilterId) {
      updateFilter(currentFilterId, { selectedValue: value });
    }
    //setModalOpen(false);
  };

  return (
    <DashboardContainer>
      <DashboardHeader 
        title="OEE DASHBOARD" 
        rightComponent={
          <Box>
            <DownloadSelector />
            <IconButton onClick={() => setSidebarOpen(true)}>
              <FilterListIcon />
            </IconButton>
          </Box>
        }
      />
      <ContentArea>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <GraphCard 
              dataSets={filters.reduce((acc, filter, index) => {
                if (oeeData[filter.id]) {
                  acc[`Graph ${index + 1}`] = oeeData[filter.id];
                }
                return acc;
              }, {} as { [key: string]: SingleSeriesGraphData })} 
              loading={loading} 
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StatisticCard title="MAX OEE" data={getStatisticData('max')} />
            <StatisticCard title="MIN OEE" data={getStatisticData('min')} />
            <StatisticCard title="AVERAGE OEE" data={getStatisticData('average')} />
          </Grid>
        </Grid>
      </ContentArea>
      <Sidebar 
        open={sidebarOpen} 
        onClose={() => setSidebarOpen(false)} 
        onDataTypeClick={handleDataTypeClick}
      />
      <DataTypeSelectorModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        dataType={filters.find(f => f.id === currentFilterId)?.dataType || 'overall'}
        onDataTypeChange={handleDataTypeChange}
        selectedValue={filters.find(f => f.id === currentFilterId)?.selectedValue || null}
        onValueChange={handleValueChange}
      />
    </DashboardContainer>
  );
};

const OEEAnalysisDashboard: React.FC = () => (
  <OEEProvider>
    <OEEAnalysisDashboardContent />
  </OEEProvider>
);

export default OEEAnalysisDashboard;

///////////////// better ///////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////////////////////////
/////////////////////////s//////////////////////////

///////////////////////////////////
//////////////////////////////////
///////////////////////////////////////////