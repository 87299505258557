import moment from 'moment';
import { useEffect, useState } from 'react';
import { PersitedDatePicker, TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import axios from 'axios';
import { apiConstant } from '../../constant';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  setModuleData: any;
  setFilteredSummary: any;
  isView: boolean;
}

const THead = ({ moduleData, isView ,setModuleData,setFilteredSummary}: IProps) => {
  const [render, setRender] = useState(false);
  // const [filteredSummary, setFilteredSummary] = useState([]); // For filtered display

  const { id } = useParams();
  const date = useQuery('date');

  // useEffect(() => {
  //   axios
  //     .get(`/api/${apiConstant.getPlanByid}/${id}`)
  //     .then((res) => {
  //       if (res?.data) {
  //         // Keep the full module data intact
  //         setModuleData({ ...res.data });
  
  //         // Create a filtered summary for display only, based on the selected month
  //         const startOfMonth = moment(date).startOf('month').toISOString();
  //         const endOfMonth = moment(date).endOf('month').toISOString();
  
  //         // Filter summary data for the selected month
  //         const filteredSummary = res.data?.summary?.filter((item: any) => {
  //           const auditDate = moment(item?.audit_date).toISOString();
  //           return auditDate >= startOfMonth && auditDate <= endOfMonth;
  //         });
  
  //         // Update the local state to show the filtered summary (but keep the full data in moduleData)
  //         setRender((prev:any) => ({
  //           ...prev,
  //           filteredSummary,
  //         }));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [date]);

  // const filterSummaryByMonth = (selectedMonth:any) => {
  //   const startOfMonth = moment(selectedMonth).startOf('month').toISOString();
  //   const endOfMonth = moment(selectedMonth).endOf('month').toISOString();

  //   const filtered = moduleData?.summary?.filter((item:any) => {
  //     const auditDate = moment(item.audit_date).toISOString();
  //     return auditDate >= startOfMonth && auditDate <= endOfMonth;
  //   });

  //   setFilteredSummary(filtered);
  // };

  // useEffect(() => {
  //   filterSummaryByMonth(date); // Filter based on selected date/month
  // }, [date]);
  
  useEffect(() => {
    // Fetch initial data
    axios
      .get(`/api/${apiConstant.getPlanByid}/${id}`)
      .then((res) => {
        if (res?.data) {
          // Keep the full module data intact
          setModuleData({ ...res.data });
          
          // Filter summary data for the selected month
          filterSummaryByMonth(date, res.data?.summary);
        }
      })
      .catch((err) => console.log(err));
  }, [date]);

  const filterSummaryByMonth = (selectedMonth: any, summary: any) => {
    const startOfMonth = moment(selectedMonth).startOf('month').toISOString();
    const endOfMonth = moment(selectedMonth).endOf('month').toISOString();

    const filtered = summary.filter((item: any) => {
      const auditDate = moment(item.audit_date).toISOString();
      return auditDate >= startOfMonth && auditDate <= endOfMonth;
    });
console.log("filtered data is ",filtered);
    setFilteredSummary(filtered);
  };


  const handleUpdate = (updatedData: any) => {
    // Here you would normally call an API to submit the updated data.
    // For now, let's just merge the updated summary with the existing data.

    setModuleData((prevData: any) => {
      return {
        ...prevData,
        summary: [...prevData.summary, ...updatedData.summary], // Keep previous data
      };
    });

    // Also reapply filtering after updating
    filterSummaryByMonth(date, [...moduleData.summary, ...updatedData.summary]);
  };

  useEffect(() => {
    // Call filter when the date changes
    filterSummaryByMonth(date, moduleData?.summary);
  }, [date, moduleData]);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['RM-QAS-A-010', '00', '01-04-2023']}
        heading="Process & Product Audit Summary Sheet"
      />
      <tr>
        <th colSpan={3} align="left">
          {/* Month : */}
          {/* <DatePickerMui
            label=""
            format={'MM-YYYY'}
            onChange={(date) => {
              // moduleData.month = date;
              currentMonth = date;
              // setRender((prev) => !prev);
            }}
            // onChange={(date) => {
            //   // Properly update the moduleData state using setModuleData
            //   setModuleData((prevData: any) => ({
            //     ...prevData,
            //     month: date,
            //   }));
            //   setRender((prev) => !prev); // Trigger re-render if necessary
            // }}
            views={['month', 'year']}
            value={moment(moduleData.month || null)}
          /> */}
           <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={`Search by month`}
          views={['year', 'month']}
        />
      </Box>
        </th>
        <th colSpan={8}></th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.No',
          'Audit Date',
          'Part Name',
          'Part No.',
          'Conforming / Not Conforming(NC)',
          'Total No. of Ncs.',
          'NC Status',
          'NC Closing Date',
          'Audit Status',
        ].map((head, index) => (
          <th
            key={head}
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            style={
              index === 2
                ? { minWidth: '300px', textTransform: 'uppercase' }
                : { minWidth: '', textTransform: 'uppercase' }
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['Open', 'Close'].map((head, index) => (
          <th key={head + index} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
