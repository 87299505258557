import { useState, useEffect } from 'react';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { apiMapping } from './apiMapping';
import { GraphData, SingleSeriesGraphData, MultiSeriesGraphData } from './types';

export const useGraphData = (selectedDate: Dayjs) => {
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    const daysInMonth = selectedDate.daysInMonth();
    const zeroFilledArray = Array(daysInMonth).fill(100);
    try {
      const requests = Object.values(apiMapping).map(endpoint =>
        axios.get(`${endpoint}?date=${selectedDate}`)
      );
      const responses = await Promise.all(requests.map(p => p.catch(e => e)));
      const newGraphData = responses.map((response, index) => {
        if (response instanceof Error) {
          console.error(`Error fetching data for endpoint ${Object.values(apiMapping)[index]}:`, response);
          return {
            title: `Graph ${index + 1}`,
            xTitle: 'Days',
            yTitle: 'Value',
            endpoint: Object.values(apiMapping)[index],
            graphData: zeroFilledArray,
            multiSeries: false,
          } as GraphData;
        }
        return response.data as GraphData;
      });
      setGraphData(newGraphData);
    } catch (err) {
      setError('Failed to fetch graph data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Fetch data every 30 seconds

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [selectedDate]);

  return { graphData, isLoading, error, refreshData: fetchData };
};