import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../../components/common/SelectField';
import { Checkbox, Grid, MenuItem } from '@mui/material';
import TextAreaInput from '../../../../../components/common/TextAreaInput';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  filteredSummary: any;
}

const getNc = (summary: any, status: any) => {
  if (status === 'open') {
    return summary.reduce((sum: number, curr: any) => {
      if (curr.open === true && curr.no_of_ncs) return sum + +curr.no_of_ncs;
      return sum;
    }, 0);
  }
  if (status === 'close') {
    return summary.reduce((sum: number, curr: any) => {
      if (curr.close === true && curr.no_of_ncs) return sum + +curr.no_of_ncs;
      return sum;
    }, 0);
  }
  return summary.reduce((sum: number, curr: any) => {
    if (curr.no_of_ncs) return sum + +curr.no_of_ncs;
    return sum;
  }, 0);
};
const getNCStatus = (summary: any, status: any) => {
  return summary.reduce((sum: number, curr: any) => {
    if (curr.status === status) return ++sum;
    return sum;
  }, 0);
};
const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  filteredSummary,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {
      // moduleData.summary &&
      //   moduleData.summary.map((spec: any, index: number) => (
        filteredSummary?.map((spec: any, index: number) => (
          <>
            <tr key={`${index}_table_row`}>
              <td>{index + 1}</td>
              <td style={{ minWidth: '200px' }}>
                {isView ? (
                  formatDate(spec.audit_date)
                ) : (
                  <DatePickerMui
                    value={moment(spec.audit_date)}
                    onChange={(date: any) => {
                      spec.audit_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td align="center">
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  {spec?.part_name}
                  {!spec?.part_name && 'Pick Part Name'}
                  {spec.part_name ? (
                    <Grid item xs={2}>
                      <RemoveIconButton
                        tooltipTitle="remove part"
                        onClick={() => {
                          spec.a = null;
                          spec.part_name = null;
                          spec.part_number = null;
                          setRender((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={2}>
                      <AddIconButton
                        tooltipTitle="add part"
                        onClick={() => {
                          setIsOpenPopup(true);
                          setPartIdx(index);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </td>
              <td style={{ minWidth: '200px' }}>{spec?.part_number}</td>
              <td align="left">
                {isView ? (
                  spec.status
                ) : (
                  <CustomSelect
                    name="status"
                    value={spec.status}
                    onChange={(e: any) => {
                      spec.status = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="c">C</MenuItem>
                    <MenuItem value="nc">NC</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="no_of_ncs"
                  type="number"
                  value={spec.no_of_ncs}
                  onChange={(e: any) => {
                    spec.no_of_ncs = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="open"
                  checked={spec.open || false}
                  onChange={(e: any) => {
                    spec.open = e.target.checked;
                    if (spec.open === true) {
                      spec.close = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td align="left">
                <Checkbox
                  name="close"
                  checked={spec.close || false}
                  onChange={(e: any) => {
                    spec.close = e.target.checked;
                    if (spec.close === true) {
                      spec.open = false;
                    }
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                {isView ? (
                  formatDate(spec.nc_closing_date)
                ) : (
                  <DatePickerMui
                    value={moment(spec.nc_closing_date)}
                    onChange={(date: any) => {
                      spec.nc_closing_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="audit_status"
                  type="text"
                  value={spec.audit_status}
                  onChange={(e: any) => {
                    spec.audit_status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          </>
        ))}
      <tr>
        <th colSpan={2} align="left">
          Total No. of Nc Raised{' '}
        </th>
        <td colSpan={2}>{getNc(moduleData.summary, 'all')}</td>
        <th colSpan={4} rowSpan={2} align="left">
          Total No. of Nc Open
        </th>
        <td colSpan={3} rowSpan={2}>
          {getNc(moduleData.summary, 'open')}
        </td>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Total No. of Nc Closed{' '}
        </th>
        <td colSpan={2}>{getNc(moduleData.summary, 'close')}</td>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Nc Status : <br></br>C : {getNCStatus(moduleData.summary, 'c')}{' '}
          <br></br>
          NC : {getNCStatus(moduleData.summary, 'nc')}
        </th>
        <td colSpan={9}></td>
      </tr>
    </tbody>
  );
};

export default TBody;
