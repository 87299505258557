import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { LineWeight } from '@mui/icons-material';
import { endpointMapping } from '../helpers/endpointMapping';

interface TableData {
  headers: string[];
  data: any[][];
  title: any;
}

interface DashBoardTableProps {
    date: any;
    endpoint: string;
    frozenColumns?: number;
}
  

const DashBoardTable: React.FC<DashBoardTableProps> = ({ date, endpoint, frozenColumns }) => {
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/${endpointMapping[endpoint]?.firstLevel || ''}?date=${date}`);
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [date, endpoint]);

  const scrollTable = (direction: 'left' | 'right') => {
    if (tableContainerRef.current) {
      const scrollAmount = 600;
      const currentScroll = tableContainerRef.current.scrollLeft;
      tableContainerRef.current.scrollTo({
        left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const tableHeaderStyle = {
    backgroundColor: '#f3ddc5',
    color: '#395032',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
  };

  const tableDataCellStyle = {
    padding: '12px',
    textAlign: 'center',
    transition: 'background-color 0.3s',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
  };

  const frozenColumnStyle = (index: number) => ({
    position: 'sticky',
    left: `${index * 100}px`, // Assuming each column is 100px wide
    zIndex: 1,
    backgroundColor: '#f3ddc5',
    fontWeight: 'bold',
    color: '#395032',

  });

  const hideScrollbarStyle = {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    'overflow': 'auto',
  };

  const scrollButtonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiSvgIcon-root': {
      color: 'black',
    },
  };

  return (
    <Box sx={{ margin: '15px' }}>
      <Paper elevation={3} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Typography sx={{ p: 1, backgroundColor: '#d1f3c5', color: '#395032', textAlign: 'center', fontSize: 'large' }}>
          {tableData?.title || ''}
        </Typography>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - 220px)',
                ...hideScrollbarStyle,
              }}
              ref={tableContainerRef}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {tableData?.headers.map((header, index) => (
                      <TableCell 
                        key={index} 
                        sx={{
                             ...tableHeaderStyle, 
                             minWidth: '100px', 
                             ...(index < (frozenColumns || 0) ? frozenColumnStyle(index) : {}) ,
                             ...(index < (frozenColumns || 0) ? {zIndex:3}: {})
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.data.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                        '&:hover': { backgroundColor: '#e8f4ff' },
                        transition: 'background-color 0.3s',
                      }}
                    >
                      {row.slice(1).map((cell, cellIndex) => (
                        <TableCell 
                          key={cellIndex} 
                          sx={{
                            ...tableDataCellStyle,
                            ...(cellIndex < (frozenColumns || 0) ? frozenColumnStyle(cellIndex) : {}),
                          }}
                        >
                          {cell? cell : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}>
        <IconButton
          onClick={() => scrollTable('left')}
          sx={{
            ...scrollButtonStyle,
            left: 20,
            zIndex: 4,
            pointerEvents: 'auto',
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => scrollTable('right')}
          sx={{
            ...scrollButtonStyle,
            right: 20,
            zIndex: 4,
            pointerEvents: 'auto',
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DashBoardTable;