import React, { useState, useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
} from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../../components/common/Modal';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import formatDate from '../../../../components/common/formatDate';
import { convToTime } from '../../../../utils/helpers';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'DATE', rowspan: 1, colspan: 1 },
    { text: 'LOCATION', rowspan: 1, colspan: 1 },
    { text: 'MACHINE NAME', rowspan: 1, colspan: 1 },
    { text: 'MACHINE NUMBER', rowspan: 1, colspan: 1 },
    { text: 'Complaint Recevied At', rowspan: 1, colspan: 1 },
    { text: 'Complaint Rectified At', rowspan: 1, colspan: 1 },
    { text: 'Time Taken', rowspan: 1, colspan: 1 },
    { text: 'Complaint Recevied', rowspan: 1, colspan: 1 },
    { text: 'Work Done', rowspan: 1, colspan: 1 },
    { text: 'Spare used', rowspan: 1, colspan: 1 },
    { text: 'No. of spare used', rowspan: 1, colspan: 1 },
    { text: 'Remarks/Status', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const getTimeTaken = (
  complaint_rectified_at: any,
  complaint_recevied_at: any
) => {
  const h1 = new Date(complaint_recevied_at).getHours();
  const h2 = new Date(complaint_rectified_at).getHours();
  const m1 = new Date(complaint_recevied_at).getMinutes();
  const m2 = new Date(complaint_rectified_at).getMinutes();
  let hours = 0;
  let minutes = 0;
  if (m2 >= m1) {
    hours = h2 - h1;
    minutes = m2 - m1;
  } else {
    hours = h2 - h1 - 1;
    minutes = 60 - m1 + m2;
  }
  let timeTaken = '';
  if (hours === 0) timeTaken += '00';
  else {
    if (hours < 10) timeTaken += '0' + hours;
    else timeTaken += hours;
  }
  if (minutes === 0) timeTaken += ':00';
  else {
    if (minutes < 10) timeTaken += ':0' + minutes;
    else timeTaken += ':' + minutes;
  }
  return timeTaken;
};
const getTime = (time: any) => {
  const hours = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();
  let t = '';
  if (hours < 10) t += '0' + hours;
  else t += hours;
  if (minutes < 10) t += ':0' + minutes;
  else t += ':' + minutes;
  return t;
};

const getTotalTimeTaken = (work_done: any, complaint_recevied: any, complaint_rectified_at: any, complaint_recevied_at: any) => {
  const complaintReceivedDate = new Date(complaint_recevied);
  const workDoneDate = new Date(work_done);
  const complaintReceivedAt = new Date(complaint_recevied_at);
  const complaintRectifiedAt = new Date(complaint_rectified_at);

  const dayDifference = Math.floor((workDoneDate.getTime() - complaintReceivedDate.getTime()) / (1000 * 3600 * 24));
  const hoursFromDays = dayDifference * 24; 
  const hours1 = complaintReceivedAt.getHours();
  const minutes1 = complaintReceivedAt.getMinutes();
  const hours2 = complaintRectifiedAt.getHours();
  const minutes2 = complaintRectifiedAt.getMinutes();
  
  let totalHours = hours2 - hours1 + hoursFromDays;
  let totalMinutes = minutes2 - minutes1;

  if (totalMinutes < 0) {
    totalHours -= 1;
    totalMinutes += 60;
  }

  const formattedTimeTaken = `${totalHours < 10 ? '0' + totalHours : totalHours}:${totalMinutes < 10 ? '0' + totalMinutes : totalMinutes}`;
  return formattedTimeTaken;
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [maintenaceLogId, setMaintenanceLogId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const date = useQuery('date') || '';

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Breakdown',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `/api/maintenaceLogRoute/?date=${date}&page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      );

      setData(res?.data?.results);
      setPerPage(res?.data?.perPage);
      setTotalPages(res?.data?.pages);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/maintenaceLogRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter, date]);

  return (
    <>
      <ChildHeader text="Breakdown Log Book">
        <AddButton onClick={() => setIsModal(true)} label="NEW Breakdown Log" />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['year', 'month']} />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item.date && formatDate(item.date)}
                  </TableCell>
                  <TableCell align="center">{item.location}</TableCell>
                  <TableCell align="center">
                    {item.machine_id?.machine_name}
                  </TableCell>
                  <TableCell align="center">
                    {item.machine_id?.machine_no}
                  </TableCell>
                  <TableCell align="center">
                    {item.complaint_recevied_at &&
                      convToTime(item.complaint_recevied_at)
                      // getTime(item.complaint_recevied_at)
                      }
                  </TableCell>
                  <TableCell align="center">
                    {item.complaint_rectified_at &&
                      convToTime(item.complaint_rectified_at)}
                  </TableCell>
                  <TableCell align="center">
                    {/* {item.complaint_recevied_at &&
                      item.complaint_rectified_at &&
                      getTimeTaken(
                        item.complaint_rectified_at,
                        item.complaint_recevied_at
                      )} */}
                       {item?.time_taken
                      //  item.work_done
                        // && item.complaint_recevied && item.complaint_recevied_at && item.complaint_rectified_at &&
    // getTotalTimeTaken(
    //   item.work_done,
    //   item.complaint_recevied,
    //   item.complaint_rectified_at,
    //   item.complaint_recevied_at
    // )
    }
                  </TableCell>
                  <TableCell align="center">
                    {/* {item.complaint_recevied} */}
                    {item.complaint_recevied && formatDate(item.complaint_recevied)}
                  </TableCell>
                  <TableCell align="center">
                    {/* {item.work_done} */}
                    {item.work_done && formatDate(item.work_done)}
                    </TableCell>
                  <TableCell align="center">{item?.spare_part}</TableCell>
                  <TableCell align="center">{item.spare_qty}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setMaintenanceLogId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomerConcern(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setMaintenanceLogId(null);
          setIsModal(false);
        }}
        title="Breakdown Log"
      >
        <Create id={maintenaceLogId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
