import React, { useState, useEffect } from 'react';
import CustomGraph from './CustomGraph';
import { GraphData , SingleSeriesGraphData, MultiSeriesGraphData, MultiLineGraphData } from '../../helpers/types';
import MultiSeriesGraph from '../multiSeriesGraph/MultiSeriesGraph';
import MultiLineGraph from './MultiLineGraph';
import { Paper, Typography, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #e6f2ff'
  }));
  
  const CardHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#e6f2ff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));
  
  const GraphArea = styled(Box)({
    flexGrow: 1,
    padding: '16px',
    backgroundColor: '#f7fbff',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });
  
  const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '80px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: 'white',
    },
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '12px',
    textTransform: 'none',
    backgroundColor: '#56a1f1',
    color: 'white',
    height: '40px',
    '&:hover': {
      backgroundColor: '#105cae',
    },
  }));


interface GraphCardProps {
  data: GraphData;
  target: number;
  updateTarget: (newValue: number) => void;
  onAnalyze: (endpoint: string, title: string, target: number) => void;
}

const GraphCard: React.FC<GraphCardProps> = ({ data ,target , updateTarget,  onAnalyze }) => {
  //const [target, setTarget] = useState<number>(data.target || 0);

  //useEffect(() => {
  //  const timer = setTimeout(() => {
  //    // TODO: Implement API call to update target
  //    console.log(`Updating target for ${data.title} to ${target}`);
  //  }, 3000);
//
  //  return () => clearTimeout(timer);
  //}, [target, data.title]);

  const handleTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTarget = Number(e.target.value);
    updateTarget(newTarget);
  };

  const handleUpdateTarget = () => {
    // TODO: Implement API call to update target
    console.log(`Updating target for ${data.title} to ${target}`);
  };

  return (
    <StyledPaper>
      <CardHeader>
        <StyledTextField
          type="number"
          value={target}
          onChange={handleTargetChange}
          placeholder="Target"
          variant="outlined"
          size="small"
        />
        <Typography variant="h6" sx={{ color: '#004590', fontWeight: 'bold' , fontSize:'15px'}}>
          {data.title}
        </Typography>
        {data.endpoint && (
          <StyledButton
            onClick={() => 
              onAnalyze(data.endpoint, data.title, target)
            }
            size="small"
          >
            Analyze
          </StyledButton>
        )}
      </CardHeader>
      <GraphArea>
        {data.multiSeries ? (
          'graphData' in data && Array.isArray(data.graphData[0]) ? (
            <MultiLineGraph data={data as MultiLineGraphData} target={target} />
          ) : (
            <MultiSeriesGraph data={data as MultiSeriesGraphData} target={target} />
          )
        ) : (
          <CustomGraph data={data as SingleSeriesGraphData} target={target} />
        )}
      </GraphArea>
    </StyledPaper>
  );
};

export default GraphCard;