import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setModuleData: any;
  isView: boolean;
  machineData: any;
  uploadImageFile: any;
}

const TController = ({
  moduleData,
  isView,
  handleDelete,
  setModuleData,
  uploadImageFile,

  machineData,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            uploadImageFile,
            isView,
            handleDelete,
            machineData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
