import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DashBoardTable from '../components/Table';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '../../../hooks/UseQuery';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateExcel } from './CommonExcelGenerator';
import { endpointMapping } from '../helpers/endpointMapping';

const CombinedTableComponent: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get('date');
    let initialDate: Dayjs = dateParam ? dayjs(decodeURIComponent(dateParam)) : dayjs();
    
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialDate);
    const endpoint = useQuery('endpoint') || 'pendingReportsRoute';

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);
    };

    const formatDateForApi = (date: Dayjs): string => {
      return encodeURIComponent(date.toDate().toUTCString());
    };

    const handleExcelExport = () => {
        if (selectedDate) {
            const formattedDate = formatDateForApi(selectedDate);
            const apiEndpoint = `/api/${endpointMapping[endpoint]?.firstLevel || ''}?date=${formattedDate}`;
            generateExcel(selectedDate.format('YYYY-MM-DD'), apiEndpoint);
        }
    };

    const getFormattedDateForTable = (): string => {
      if (selectedDate) {
          return formatDateForApi(selectedDate);
      }
      return '';
    };

    return (
        <Box sx={{ mt: 0, p: 2.3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        views={['month', 'year']}
                        openTo="month"
                        format="MMMM YYYY"
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '40px',
                                fontSize: '1.2rem',
                            },
                            '& .MuiInputLabel-root': {
                                fontSize: '1.2rem',
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                        slotProps={{
                            textField: {
                                size: "small",
                            },
                        }}
                    />
                </LocalizationProvider>
                <Tooltip title="Export to Excel">
                    <IconButton 
                        onClick={handleExcelExport} 
                        sx={{ ml: 2 }}
                        color="primary"
                    >
                        <FileDownloadIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <DashBoardTable
                date={getFormattedDateForTable()}
                endpoint={endpoint}
                frozenColumns={1}
            />
        </Box>
    );
};

export default CombinedTableComponent;