import React, { useState, useEffect } from 'react';
import { 
  Modal,
  Box,
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Select, 
  MenuItem, 
  Input,
  Button,
  CircularProgress
} from '@mui/material';
import { IProcess, IDowntimeReason } from '../../helpers/interface';
import moment from 'moment';
import axios from 'axios';
import { useQuery } from '../../../../../hooks/UseQuery';

interface DowntimeReasonOption {
    _id: string;
    name: string;
}

interface DowntimeReasonModalProps {
  process: IProcess;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedProcess: IProcess) => void;
  documentType:any;
}

const downtimeReasons = [
  'Machine Breakdown',
  'Material Shortage',
  'Tool Change',
  'Setup Time',
  'Quality Issue',
  'Other'
];

const DowntimeReasonModal: React.FC<DowntimeReasonModalProps> = ({ process, isOpen, onClose, onSave, documentType }) => {
  const [reasons, setReasons] = useState<IDowntimeReason[]>(process.downtime_reasons || []);
  const [downtimeReasons, setDowntimeReasons] = useState<DowntimeReasonOption[]>([]);
  let viewMode = useQuery('isView') || 'false';
  console.log('view Mode:', viewMode)
  //const documentType = useQuery('documentType');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setReasons(process.downtime_reasons || []);
  }, [process]);

  useEffect(() => {
    const fetchDowntimeReasons = async () => {
      try {
        const response = await axios.get('/api/downtimeReasonRoute/autocomplete');
        setDowntimeReasons(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching downtime reasons:', error);
        setLoading(false);
      }
    };

    fetchDowntimeReasons();
  }, []);

  const addRow = () => {
    setReasons([...reasons, { reason: '', duration: 0 }]);
  };

  const updateReason = (index: number, field: keyof IDowntimeReason, value: string | number) => {
    const updatedReasons = reasons.map((reason, i) => 
      i === index ? { ...reason, [field]: value } : reason
    );
    setReasons(updatedReasons);
  };

  const calculateTotalDowntime = () => {
    return reasons.reduce((sum, reason) => sum + reason.duration, 0);
  };

  const calculatePlanQty = (updatedProcess: IProcess) => {
    const cycleTime = updatedProcess.cycle_time || null;
    if(cycleTime === null || cycleTime === undefined || cycleTime === 0){
      return null;
    }

    if(!updatedProcess.time_start || !updatedProcess.time_stop){
      return null;
    }
    
    if (cycleTime && updatedProcess.time_start && updatedProcess.time_stop) {
      const startTime = moment(updatedProcess.time_start);
      const stopTime = moment(updatedProcess.time_stop);
      let durationInMinutes = stopTime.diff(startTime, 'minutes');
      if (durationInMinutes < 0) {
        durationInMinutes += 1440;
      }
      durationInMinutes -= updatedProcess.total_downtime || 0;
      const durationInHours = durationInMinutes / 60;
      return Math.floor(durationInHours * cycleTime);
    }
    return null;
  };

  const handleSave = () => {
    const totalDowntime = calculateTotalDowntime();
    const updatedProcess: IProcess = {
      ...process,
      downtime_reasons: reasons,
      total_downtime: totalDowntime,
    };
    updatedProcess.plan_qty = (documentType === 'pressShop3') ? (updatedProcess.plan_qty) : (calculatePlanQty(updatedProcess));
    onSave(updatedProcess);
    onClose();
  };

  if (loading) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="downtime-modal-title"
      aria-describedby="downtime-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box>
            <h2 id="downtime-modal-title">DOWNTIME</h2>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              {(viewMode == 'false')&&(
                <Button variant="contained" onClick={addRow} sx={{ mr: 1 }}>
                    Add Row
                </Button>
              )}
              {(viewMode == 'false')&&(
                <Button variant="contained" onClick={handleSave}>
                    Save
                </Button>
              )}
            </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="10%">S.No.</TableCell>
                <TableCell width="70%">Reason</TableCell>
                <TableCell width="20%">Duration (minutes)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reasons.map((reason, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Select
                      fullWidth
                      value={reason.reason}
                      onChange={(e) => updateReason(index, 'reason', e.target.value as string)}
                    >
                      {downtimeReasons.map((r) => (
                        <MenuItem key={r._id} value={r.name}>{r.name}</MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      value={reason.duration}
                      onChange={(e) => updateReason(index, 'duration', Number(e.target.value))}
                      inputProps={{ style: { width: '60px' } }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>Total Downtime</TableCell>
                <TableCell>{calculateTotalDowntime()} minutes</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default DowntimeReasonModal;