import React from 'react';
import { Paper, Typography, Box, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, AreaChart , Area, Legend } from 'recharts';
import { SingleSeriesGraphData } from '../../helpers/types';
import CustomGraph from './DetailedCustomGraph';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxHeight:'400px'
}));

const StyledCard = styled(Box)(({ theme }) => ({
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const CardHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: '#f8fbff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const GraphArea = styled(Box)({
    flexGrow: 1,
    padding: '16px',
    backgroundColor: '#f7fbff',
    minHeight: '400px'
});

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: '12px',
    textTransform: 'none',
    backgroundColor: 'rgba(74, 82, 64, 0.8)',
    color: 'white',
    height: '40px',
    '&:hover': {
      backgroundColor: 'rgba(107, 118, 97, 0.8)',
    },
}));

const GraphHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

interface GraphCardProps {
  dataSets: { [key: string]: SingleSeriesGraphData };
  loading: boolean;
}

const GraphCard: React.FC<GraphCardProps> = ({ dataSets, loading }) => {
  if (loading) {
    return (
      <StyledCard>
        <GraphArea display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </GraphArea>
      </StyledCard>
    );
  }

  if (Object.keys(dataSets).length === 0) {
    return (
      <StyledCard>
        <GraphArea display="flex" justifyContent="center" alignItems="center">
          No data available
        </GraphArea>
      </StyledCard>
    );
  }

  const chartData = Object.entries(dataSets).reduce((acc, [id, data]) => {
    data.graphData.forEach((value, index) => {
      if (!acc[index]) {
        acc[index] = { day: index + 1 };
      }
      acc[index][`${id}`] = value;
    });
    return acc;
  }, [] as any[]);

  const colors = [
     // Very light blue
    '#90CAF9',
    '#51a9ef',
    '#2f8ad3',
    '#126cb4',
    '#0962a9',
    '#005496',
    '#004b87'  // Dark blue
  ];


  //
  //if (!data) {
  //  return (
  //    <StyledPaper>
  //      <GraphArea display="flex" justifyContent="center" alignItems="center">
  //        No data available
  //      </GraphArea>
  //    </StyledPaper>
  //  );
  //}

  //const chartData = data.graphData.map((value, index) => ({ day: index + 1, value }));

  return (
    <StyledCard>
      <GraphArea>
        <ResponsiveContainer width="100%" height="100%" style={{marginLeft:'-30px', paddingTop:'15px'}}>
          <AreaChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(dataSets).map((id, index) => (
              <Area
                key={id}
                type="monotone"
                dataKey={`${id}`}
                stroke={colors[index % colors.length]}
                fill={colors[index % colors.length]}
                fillOpacity={0.3}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </GraphArea>
    </StyledCard>
  );
};

export default GraphCard;