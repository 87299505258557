import React from 'react';
import { Drawer, Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterCard from './FilterCard';
import { useOEE } from './OEEContext';

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 300,
  padding: theme.spacing(2),
  backgroundColor: '#f0f8ff', // Matching the theme
  height: '100%',
}));

const SidebarHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#e6f2ff', // Matching the card headers
  padding: theme.spacing(2),
  borderRadius: '8px',
  maxHeight: '72px',
  border: '1px solid #9bcbff'
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  overflowY: 'auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  textTransform: 'none',
  backgroundColor: '#9bcbff',
  color: 'white',
  height: '40px',
  '&:hover': {
    backgroundColor: '#4386cf',
  },
  marginBottom: theme.spacing(2),
}));

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  onDataTypeClick: (id: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onClose, onDataTypeClick }) => {
  const { filters, addFilter, removeFilter, updateFilter } = useOEE();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <SidebarContainer>
        <SidebarHeader>
          {/*<Typography variant="h6" gutterBottom sx={{ color: '#395032', fontWeight: 'bold' }}>Filters</Typography>*/}
          <StyledButton 
            onClick={addFilter} 
            fullWidth
          >
            Add Graph
          </StyledButton>
        </SidebarHeader>
        <SidebarContent>
          {filters.map((filter, index) => (
            <FilterCard
              key={filter.id}
              filter={filter}
              onUpdate={(updates) => updateFilter(filter.id, updates)}
              onRemove={() => removeFilter(filter.id)}
              onDataTypeClick={() => onDataTypeClick(filter.id)}
              title={`Graph ${index + 1}`}
            />
          ))}
        </SidebarContent>
      </SidebarContainer>
    </Drawer>
  );
};

export default Sidebar;