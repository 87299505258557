import React, { useEffect, useState, useCallback} from 'react';
import { Stack, TableBody, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  DeleteIconButton,
  CardIconButton,
  AddIconButton,
  CustomIconButton,
} from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../../components/common/button/CustomButtons';
import { GrStatusGood } from 'react-icons/gr';
import { FaPencilAlt } from 'react-icons/fa';
import { StyledTableRow, StyledTableCell } from './helpers/module.styled';
import TableInput from '../../components/common/tableinputs/TableInput';
import useDebouncedCycleTime from './helpers/useDebouncedCycleTime';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  // minWidth: '80px',
  border: '1px solid #343a40',
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'TOOL NAME', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'PART NAME', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'CYCLE TIME', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'CUSTOMER NAME', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'HISTORY', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'TOOL CHECKLIST', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'ACTION', rowspan: 1, colspan: 1, style: { ...style1 } },
  ],
];

const filterOptions = [
  {
    value: 'tools_under_maintenance',
    name: 'tools under maintenance'.toUpperCase(),
  },
  { value: 'tool_name', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
];

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: disabled ? 'rgba(0, 0, 0, 0.23)' : theme.palette.primary.main,
      borderWidth: disabled ? 1 : 2,
    },
    '&:hover fieldset': {
      borderColor: disabled ? 'rgba(0, 0, 0, 0.23)' : theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  //marginLeft: '5px',
  minWidth: '150px',
}));

const List = () => {
  const [data, setData] = useState<any>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);

  //const [isEditingCycleTimes, setIsEditingCycleTimes] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error'>('idle');
  
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const navigate = useNavigate();
  const [filterCustomer, setFilterCustomer] = useState([]);
  const customer = useQuery('customer');

  const {
    cycleTimeInputs,
    handleCycleTimeChange,
    isEditingCycleTimes,
    setIsEditingCycleTimes,
  } = useDebouncedCycleTime();

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?'
  );
  const [DialogMaintenance, confirmMaintenance] = useConfirm(
    'MAINTENANCE ',
    'Are you sure you want to completed the maintenance?'
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData: any = [
            { name: 'None', value: '' },
            ...res.data.map((customer: any) => ({
              name: customer.customer_name,
              value: customer._id,
            })),
          ];
          setFilterCustomer(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListOfTools?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const updateCycleTime = useCallback(async (id: string, cycleTime: number) => {
    try {
      await axios.patch(`/api/masterListOfTools/cycleTime/${id}`, { cycle_time: cycleTime });
      setRender((prev) => !prev);
    } catch (err) {
      console.error("Error updating cycle time:", err);
    }
  }, []);

  //const handleCycleTimeChange = useCallback((id: string, value: string) => {
  //  const cycleTime = parseFloat(value);
  //  if (!isNaN(cycleTime)) {
  //    updateCycleTime(id, cycleTime);
  //  }
  //}, [updateCycleTime]);

  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListOfTools/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, customer, filter]);
  const handleMaintenance = async (id: string) => {
    const ans = await confirmMaintenance();
    if (!ans) return;
    await axios
      .put(`/api/masterListOfTools/${id}`, { maintenance_done: 'completed' })
      .then((res) => {
        alert('successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ChildHeader text="TOOLS LIST"></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar label="Search" sx={{ width: { xs: '100%', sm: '50%' } }} />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
            variant={'outlined'}
          />
          <FilterBy
            sx={{ minWidth: '180px', maxWidth: '200px' }}
            options={filterOptions}
          />
          {/* EDIT CYCLE TIMES BUTTON ( Toggles the isView property of the cycle_time input fields ) */}
          <EditButton
            variant="contained"
            startIcon={isEditingCycleTimes ? <CheckIcon /> : <EditIcon />}
            onClick={() => setIsEditingCycleTimes(!isEditingCycleTimes)}
          >
            {isEditingCycleTimes ? "Stop Editing" : "Edit Cycle Time"}
          </EditButton>
        </Stack>
      </Stack>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <StyledTableRow
                  style={{
                    backgroundImage:
                      item.maintenance_done === 'pending'
                        ? 'linear-gradient(to left, #ff6b6b, #ff6b6b)'
                        : '',
                  }}
                  index={index}
                >
                  <StyledTableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.tool_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.part_id?.part_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StyledTextField
                      type="number"
                      value={cycleTimeInputs[item._id] ?? item.cycle_time ?? ''}
                      disabled={!isEditingCycleTimes}
                      onChange={(e) => handleCycleTimeChange(item._id, e.target.value)}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        sx: { 
                          '& input': { 
                            textAlign: 'center',
                            padding: '8px',
                            width: '80px'
                          }
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.part_id?.customer_id?.customer_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CardIconButton
                      tooltipTitle="Tools History"
                      onClick={() => {
                        navigate(`/common/toolsHistory/${item._id}`);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle="Check List"
                      onClick={() => {
                        navigate(
                          `/common/tool/status/home/${item._id}?tool_name=${item?.tool_name}&tool_no=${item?.tool_no}`
                        );
                      }}
                    >
                      <FaPencilAlt style={{ color: '#343a40' }} />
                    </CustomIconButton>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
      <DialogMaintenance isSubmitContent={false} />
    </>
  );
};

export default List;
