import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface DatePickerProps {
    selectedDate: Dayjs;
    onChange: (date: Dayjs | null) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ selectedDate, onChange }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          label="Select Date"
          value={selectedDate}
          onChange={onChange}
          views={['month', 'year']}
          openTo="month"
          format="MMMM YYYY"
          sx={{
            '& .MuiInputBase-root': {
              height: '40px',
              fontSize: '1.2rem',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.2rem',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
            },
          }}
          slotProps={{
            textField: {
              size: "small",
            },
          }}
        />
      </LocalizationProvider>
    );
  };
export default DatePicker;