import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Box, Typography, Paper, TextField, useMediaQuery  } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { createTheme, responsiveFontSizes  } from '@mui/material/styles';
import BarChart from './BarChart';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#3f51b5' },
    secondary: { main: '#f50057' },
    background: { default: '#f5f5f5', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#757575' },
    blueButton: {main: ''},
    greenButton: {main: ''}
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 600,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .05)',
        },
      },
    },
  },
});

const YearlyRejectionPPMGraph = () => {
  const [selectedYear, setSelectedYear] = useState(dayjs());
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchData(selectedYear);
  }, [selectedYear]);

  const fetchData = async (date:any) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/incomingPartMonitoringSheet/yearlyGraph/getRejectionPPMByMonth?date=${date.format('YYYY-MM-DD')}`);
      const formattedData = response.data.data.map((value:any, index:any) => ({
        month: monthNames[index],
        value: value
      }));
      setChartData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          boxWidth: 20,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: 'Monthly Rejection PPM',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'PPM',
          font: {
            size: 14,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ p: 3, minHeight: '100vh', bgcolor: 'background.default' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          INCOMING PART REJECTION GRAPH
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 3, display: 'flex', justifyContent: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year']}
              label="Select Year"
              value={selectedYear}
              onChange={(newValue: any) => setSelectedYear(newValue)}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
        </Paper>
        <Paper elevation={3} sx={{ p: 3, height: isMobile ? 300 : 400 }}>
          {isLoading ? (
            <Typography align="center">Loading...</Typography>
          ) : (
            <BarChart
              datasets={[
                {
                  label: 'Rejection PPM',
                  data: chartData.map((item: any) => item.value),
                  backgroundColor: 'rgba(63, 81, 181, 0.6)',
                  borderColor: 'rgba(63, 81, 181, 1)',
                  borderWidth: 1,
                }
              ]}
              monthNames={monthNames}
              options={chartOptions}
            />
          )}
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default YearlyRejectionPPMGraph;