import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#868e96',
  height: '50px',
  color: '#fff',
  fontSize: '2rem',
};

interface IProps {
  isView: boolean;
}

const THead = ({ isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const tableHeaderObj = [
    [
      {
        text: `${year} - ${year && year + 1}`,
        style: style2,
        colspan: 16,
      },
    ],
    [
      { text: 'S.No.', style: style1, rowspan: 2 },
      { text: 'BreakDown', style: style1, colspan: 2 },
      { text: 'M/C No.', style: style1, rowspan: 2 },
      { text: 'M/C Name', style: style1, rowspan: 2 },
      { text: 'Reason', style: style1, rowspan: 2 },
      { text: 'Action Taken', style: style1, rowspan: 2 },
      { text: 'B/D. Rectified', style: style1, colspan: 2 },
      { text: 'Status', style: style1, rowspan: 2 },
      { text: 'Idle Time', style: style1, rowspan: 2 },
      { text: 'Sign.', style: style1, rowspan: 2 },
      { text: 'Remarks', style: style1, rowspan: 2 },
      { text: 'Proof', style: style1, rowspan: 2 },
      { text: 'Analyse', style: style1, rowspan: 2 },
      { text: 'Action', style: style1, rowspan: 2 },
    ],
    [
      { text: 'Date', style: style1, colspan: 1 },
      { text: 'Time', style: style1, colspan: 1 },
      { text: 'Date', style: style1, colspan: 1 },
      { text: 'Time', style: style1, colspan: 1 },
    ],
  ];

  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={3}
        labels={['Doc. No.', 'Rev. No.', 'Eff.Dated']}
        values={['FRM - MNT-0-005', '00', '01-04-2023']}
        heading={`${moduleConstant?.heading}`}
      />
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
