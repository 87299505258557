import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../components/common/AlertMui';

import { SubmitButton } from '../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../components/common/FormControlMui';
import DatePickerMui from '../../../components/common/DatePickerMui';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface Iprop {
  setIsNewReasonModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setReasonId: React.Dispatch<React.SetStateAction<string | null>>;
}
export interface IDowntimeReason {
    _id?: string;
    name: string;
    type?: 'scheduled' | 'unscheduled' | null ;
    createdAt?: Date;
}

const AddEditDowntimeReason = ({ setIsNewReasonModal, id, setReasonId }: Iprop) => {
  const [reasonData, setreasonData] = useState<any>({
    name: '',
    type: 'unscheduled'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`,
  );

  const handlereasonData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setreasonData((prev:any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomers(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/downtimeReasonRoute/${id}`)
        .then((res) => {
          setreasonData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setreasonData((prev:any) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/downtimeReasonRoute/${id}`, reasonData)
        .then((res) => {
          setIsLoading(false);
          setIsNewReasonModal(false);
          setReasonId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/downtimeReasonRoute', reasonData)
        .then((res) => {
          setIsLoading(false);
          setIsNewReasonModal(false);
          setReasonId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="name"
              placeholder="Downtime reason"
              required={true}
              onChange={handlereasonData}
              value={reasonData?.name || ''}
            />
            
            <CustomSelect
                name="dropdown"
                value={reasonData.type || 'unscheduled'}
                onChange={(e) => {
                  reasonData.type = e.target.value ;
                  setRender((prev) => !prev);
                }}
                sx={{
                  minWidth: '90px',
                  textAlign: 'center',
                }}
              >
                <MenuItem value="scheduled">Scheduled</MenuItem>
                <MenuItem value="unscheduled">Unscheduled</MenuItem>
              </CustomSelect>
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditDowntimeReason;
