import React , {useEffect} from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import DataTypeSelector from './DataTypeSelector';

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  backgroundColor: theme.palette.background.paper,
}));

const ModalHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#ffd5d5',
}));

const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff2f2',
}));

interface DataTypeSelectorModalProps {
  open: boolean;
  onClose: () => void;
  dataType: 'overall' | 'machine' | 'operator';
  onDataTypeChange: (type: 'overall' | 'machine' | 'operator') => void;
  selectedValue: string | null;
  onValueChange: (value: string | null) => void;
}


const DataTypeSelectorModal: React.FC<DataTypeSelectorModalProps> = ({
  open,
  onClose,
  dataType,
  onDataTypeChange,
  selectedValue,
  onValueChange
}) => {
  useEffect(() => {
    console.log('DataTypeSelectorModal received new props:', { dataType, selectedValue });
  }, [dataType, selectedValue]);

  const getModalTitle = () => {
    switch (dataType) {
      case 'machine':
        return 'Select Machine';
      case 'operator':
        return 'Select Operator';
      default:
        return 'Select Data Type';
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Typography variant="h6">{getModalTitle()}</Typography>
        </ModalHeader>
        <ModalBody>
          <DataTypeSelector
            dataType={dataType}
            onDataTypeChange={onDataTypeChange}
            selectedValue={selectedValue}
            onValueChange={onValueChange}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DataTypeSelectorModal;