import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 50px',
  backgroundColor: '#f8fbff',
  marginBottom: '20px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const HeaderTitle = styled(Typography)({
  color: '#002750',
  fontWeight: 'bold',
});

interface DashboardHeaderProps {
  title: string;
  rightComponent?: React.ReactNode;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title, rightComponent }) => {
  return (
    <HeaderContainer>
      <HeaderTitle variant="h5" >{title}</HeaderTitle>
      {rightComponent}
    </HeaderContainer>
  );
};

export default DashboardHeader;