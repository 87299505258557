import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  LinearProgress,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from '../../../../hooks/UseQuery';
import BreakdownModal from './BreakDownListModal';
import { endpointMapping } from '../../helpers/endpointMapping';

interface TableData {
  headers: string[];
  data: any[][];
  title: string;
  message: string;
  endpoint: string;
  type: string;
}

const MinorBreakdownTable: React.FC = () => {
  const [tableData, setTableData] = useState<TableData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBreakdowns, setSelectedBreakdowns] = useState<any[]>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const selectedDate = useQuery('date');
  const endpoint = useQuery('endpoint') || '';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/${endpointMapping[endpoint]?.firstLevel || ''}?date=${selectedDate || new Date().toISOString()}`);
        setTableData(response.data);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedDate, endpoint]);

  const scrollTable = (direction: 'left' | 'right') => {
    if (tableContainerRef.current) {
      const scrollAmount = 600;
      const currentScroll = tableContainerRef.current.scrollLeft;
      tableContainerRef.current.scrollTo({
        left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handleViewClick = (breakdowns: any[]) => {
    setSelectedBreakdowns(breakdowns);
    setModalOpen(true);
  };

  const tableHeaderStyle = {
    backgroundColor: '#f3ddc5',
    color: '#395032',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '12px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
  };

  const tableDataCellStyle = {
    padding: '12px',
    textAlign: 'center',
    transition: 'background-color 0.3s',
    borderRight: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    borderLeft: '1px solid #e0e0e0',
  };

  const frozenColumnStyle = (index: number) => ({
    position: 'sticky',
    left: `${index * 100}px`,
    zIndex: 1,
    backgroundColor: '#f3ddc5',
    fontWeight: 'bold',
    color: '#395032',
  });

  const hideScrollbarStyle = {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbarWidth': 'none',
    'overflow': 'auto',
  };

  const scrollButtonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiSvgIcon-root': {
      color: 'black',
    },
  };

  return (
    <Box sx={{ mt: 0, p: 2.3 }}>
      <Paper elevation={3} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        <Typography sx={{ p: 1, backgroundColor: '#d1f3c5', color: '#395032', textAlign: 'center', fontSize: 'large' }}>
          {tableData?.title || ''}
        </Typography>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <TableContainer
              sx={{
                maxHeight: 'calc(100vh - 220px)',
                ...hideScrollbarStyle,
              }}
              ref={tableContainerRef}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {tableData?.headers.map((header, index) => (
                      <TableCell 
                        key={index} 
                        sx={{
                          ...tableHeaderStyle, 
                          minWidth: '100px', 
                          ...(index === 0 ? frozenColumnStyle(0) : {}),
                          ...(index === 0 ? {zIndex: 3} : {})
                        }}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.data.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' },
                        '&:hover': { backgroundColor: '#e8f4ff' },
                        transition: 'background-color 0.3s',
                      }}
                    >
                      {row.map((cell, cellIndex) => (
                        <TableCell 
                          key={cellIndex} 
                          sx={{
                            ...tableDataCellStyle,
                            ...(cellIndex === 0 ? frozenColumnStyle(0) : {}),
                          }}
                        >
                          {cellIndex === 0 ? (
                            cell
                          ) : (
                            <>
                              {Array.isArray(cell) && cell.length > 0 && (
                                <>
                                  {cell.length}
                                  <IconButton
                                    size="small"
                                    onClick={() => handleViewClick(cell)}
                                    sx={{ ml: 1 }}
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}>
        <IconButton
          onClick={() => scrollTable('left')}
          sx={{
            ...scrollButtonStyle,
            left: 20,
            zIndex: 4,
            pointerEvents: 'auto',
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={() => scrollTable('right')}
          sx={{
            ...scrollButtonStyle,
            right: 20,
            zIndex: 4,
            pointerEvents: 'auto',
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <BreakdownModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        breakdowns={selectedBreakdowns}
      />
    </Box>
  );
};

export default MinorBreakdownTable;