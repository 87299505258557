import moment from 'moment';
import { useState } from 'react';
import { TableCompHead } from '../../../../../../../components/ui';
import { useQuery } from '../../../../../../../hooks/UseQuery';
import formatDate from '../../../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const heading = [
  'S. No.',
  'Check Points',
  'C (5)',
  'O (2)',
  'NC (0)',
  'Actual Observation',
];

const getLastDayOfMonth = (year: number, month: number) => {
  return moment(`${year}-${month}`, 'YYYY-MM').endOf('month').format('D MMMM YYYY');
};

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const part_no = useQuery('part_no');
  const part_name = useQuery('part_name');
  const due_date:any = useQuery('dueDate');

  const year = moment(due_date).year();
  const month = moment(due_date).month() + 1; // Months are 0-indexed in moment

  

  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['Doc. No.: ', 'Rev. No.:', 'Eff. Date:']}
        values={['RM-QAS-A-007', '00', '01-04-2023']}
        heading="Process Audit Check Sheet "
      />
      <tr style={{ height: "50px" }}>
        <th style={{ fontSize: "20px" }}>Part Name : {part_name}</th>
        <th style={{ fontSize: "20px" }}>Part No. : {part_no}</th>
        <th colSpan={4} align="left" style={{ fontSize: "20px" }}>
          Audit Date :  
           {formatDate(due_date)}
          {/* {due_date} */}
          {/* Audit Date :{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )} */}
        </th>
      </tr>

      <tr style={{ height: "50px" }}>
        {heading.map((header) => (
          <th
            style={header === "C (5)" ? { background: "#69db7c", fontSize: "20px" } : (header === "O (2)" ? { background: "#ffd43b", fontSize: "20px" } : (header === "NC (0)" ? { background: "#ff8787", fontSize: "20px" } : { fontSize: "20px" }))}
            key={header}
            colSpan={
              (header === 'Observation' && 2) ||
              (header === 'Action Taken' && 3) ||
              1
            }
            rowSpan={
              header === 'Observation' || header === 'Action Taken' ? 1 : 2
            }
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
