import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine } from 'recharts';
import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import { MultiLineGraphData } from '../../helpers/types';

const StyledTooltip = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid #ccc',
}));

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

interface MultiLineGraphProps {
  data: MultiLineGraphData;
  target: number;
}

const MultiLineGraph: React.FC<MultiLineGraphProps> = ({ data, target }) => {
  const processedData = data.graphData[0].slice(2).map((_, index) => {
    const obj: { [key: string]: any } = { day: index + 1 };
    data.graphData.forEach((series) => {
      obj[series[1]] = series[index + 2];
    });
    return obj;
  });

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <Typography variant="body2" fontSize={11}>Day: {label}</Typography>
          {payload.map((entry: any, index: number) => (
            <Typography key={index} variant="body1" color={entry.color} fontWeight="bold" fontSize={12.5}>
              {entry.name}: {entry.value.toFixed(2)}
            </Typography>
          ))}
        </StyledTooltip>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={processedData} margin={{ top: 5, right: 15, left: -30, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(0,0,0,0.1)" />
        <XAxis 
          dataKey="day" 
          stroke="rgba(0,0,0,0.5)"
          tickCount={31}
          type="number"
          domain={[1, 31]}
        />
        <YAxis stroke="rgba(0,0,0,0.5)" />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <ReferenceLine 
          y={target} 
          stroke="rgba(255,165,0,1)"
          strokeDasharray="3 3"
          strokeWidth={2}
        />
        {data.graphData.map((series, index) => (
          <Line
            key={series[1]}
            type="monotone"
            dataKey={series[1]}
            stroke={colors[index % colors.length]}
            strokeWidth={2}
            dot={{ r: 3, strokeWidth: 1, fill: colors[index % colors.length], stroke: '#fff' }}
            activeDot={{ r: 5, strokeWidth: 1, fill: colors[index % colors.length], stroke: '#fff' }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MultiLineGraph;