import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import TController from './components/TController';
import ModalCustom from '../../../../components/common/Modal';
import PickPartAnnual from './components/PickPartAnnualPlan';
import { apiConstant } from '../constant';
import lodash from 'lodash';

const CreateSummarySheet = () => {
  const [moduleData, setModuleData] = useState<any>({
    summary: [],
    // revision_history: Array.from({ length: 5 }, () => ({
    //   ...lodash.cloneDeep({}),
    // })),
  });
  const [filteredSummary, setFilteredSummary] = useState<any>([]);

  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const { id } = useParams();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState(0);
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          if (res.data) {
            const newData = { ...moduleData, ...res.data };
            if (
              res.data?.revision_history &&
              res.data?.revision_history?.length === 0
            ) {
              newData.revision_history = res.data.revision_history;
            }
            setModuleData(newData);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?',
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    console.log("form submission moduleData is ",moduleData);
    console.log("form submission filteredSummary is ",filteredSummary);
    const updatedModuleData = {
      ...moduleData,
      summary: [...moduleData.summary, ...filteredSummary], // Merge existing summaries with filteredSummary
    };
    console.log("form submission updatedModuleData is ",updatedModuleData);
    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, updatedModuleData)
      .then((res) => {

        alert('updated successfully ')
        setModuleData(updatedModuleData);
      }
    )
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    let data1 = { ...filteredSummary };
    data.summary.splice(rowIdx, 1);
    filteredSummary.splice(rowIdx, 1);
    setModuleData(data);
    setFilteredSummary(filteredSummary);
  };

  return (
    <>
      <Box>
        <ChildHeader text="Process & Product Audit Summary Sheet">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  // const ans = await confirmAddRow();
                  // if (!ans) return;
                  // moduleData.summary.push({});
                  filteredSummary.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <UpdateButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          setIsOpenPopup,
          setPartIdx,
          setModuleData,
          setFilteredSummary,
  filteredSummary,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPartAnnual
          moduleData={moduleData.summary[partIdx]}
          filteredSummary={filteredSummary[partIdx]}
          {...{
            partIdx,
          }}
        />
      </ModalCustom>
    </>
  );
};

export default CreateSummarySheet;
