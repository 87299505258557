import React, { createContext, useContext, useState, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '../../../../hooks/UseQuery';

export interface FilterState {
  id: string;
  //graphNumber: number;
  selectedDate: Dayjs;
  dataType: 'overall' | 'machine' | 'operator';
  selectedValue: string | null;
}

export interface OEEContextType {
  filters: FilterState[];
  addFilter: () => void;
  removeFilter: (id: string) => void;
  updateFilter: (id: string, updates: Partial<FilterState>) => void;
  oeeData: { [key: string]: any };
  setOeeData: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  //getNextGraphNumber: () => number;
}

const OEEContext = createContext<OEEContextType | undefined>(undefined);

export const OEEProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const dateParam = urlParams.get('date');
  let initialDate: Dayjs;
  if (dateParam) {
    initialDate = dayjs(decodeURIComponent(dateParam));
  } else {
    initialDate = dayjs();
  }

  const [filters, setFilters] = useState<FilterState[]>([
    { id: '1', selectedDate: initialDate, dataType: 'overall', selectedValue: null }
  ]);
  const [oeeData, setOeeData] = useState<{ [key: string]: any }>({});
  const [graphCounter, setGraphCounter] = useState(1);

  const getNextGraphNumber = useCallback(() => {
    setGraphCounter(prevCounter => prevCounter + 1);
    return graphCounter + 1;
  }, [graphCounter]);


  const addFilter = useCallback(() => {
    const newId = (filters.length + 1).toString();
    setFilters(prev => [...prev, { 
      id: newId, 
      selectedDate: dayjs(), 
      dataType: 'overall', 
      selectedValue: null 
    }]);
  }, [filters]);

  const removeFilter = useCallback((id: string) => {
    setFilters(prev => {
      const updatedFilters = prev.filter(filter => filter.id !== id);
      // Reassign IDs to maintain consecutive order
      return updatedFilters.map((filter, index) => ({
        ...filter,
        id: (index + 1).toString()
      }));
    });
  }, []);

  const updateFilter = useCallback((id: string, updates: Partial<FilterState>) => {
    setFilters(prev => prev.map(filter => 
      filter.id === id ? { ...filter, ...updates } : filter
    ));
  }, []);

  return (
    <OEEContext.Provider value={{ 
      filters, 
      addFilter, 
      removeFilter, 
      updateFilter, 
      oeeData, 
      setOeeData,
      //getNextGraphNumber 
    }}>      {children}
    </OEEContext.Provider>
  );
};

export const useOEE = () => {
  const context = useContext(OEEContext);
  if (context === undefined) {
    throw new Error('useOEE must be used within an OEEProvider');
  }
  return context;
};