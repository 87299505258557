import React from 'react';
import { Paper, Box, Typography, List, ListItem, ListItemText, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
}));

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(2),
  minHeight: '200', // Set a minimum height
  maxWeight: '250px', // Set a maximum height
  overflowY: 'auto', // Allow scrolling if content exceeds max height
  alignItems: 'center',
  border: '2px solid #e6f2ff'
}));



const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#e6f2ff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#f7fbff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StatItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#e6f2ff',
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  minWidth: '100px', // Set a minimum width for each stat item
}));

const CircularProgressWrapper = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
});

const CircularProgressValue = styled(Typography)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface StatisticCardProps {
  title: string;
  data: { title: string; value: number }[];
}


const StatisticCard: React.FC<StatisticCardProps> = ({ title, data }) => {
  return (
    <StyledCard>
      <CardHeader>
      <Typography variant="h6" sx={{ color: '#003b7a', fontWeight: 'bold' }}>{title}</Typography>
      </CardHeader>
      <CardContent>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Grid item xs={6} key={index}>
              <StatItem>
                <Typography variant="body2" sx={{color: '#002750'}}>{item.title}</Typography>
                <Typography variant="h6" sx={{ color: '#003b7a', fontWeight: 'bold' }}>
                  {!isFinite(item.value) || isNaN(item.value) ? '---' : `${item.value.toFixed(2)}%`}
                </Typography>
              </StatItem>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default StatisticCard;