import { AiOutlineCloudUpload, AiOutlinePartition } from 'react-icons/ai';
import { AiOutlineFundView } from 'react-icons/ai';
import { GrVmMaintenance } from 'react-icons/gr';
import { FaToolbox, FaUserCog } from 'react-icons/fa';
import { GoTools } from 'react-icons/go';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { CgArrowsBreakeV } from 'react-icons/cg';
import { TbGauge } from 'react-icons/tb';
import {
  MdChecklistRtl,
  MdDriveFolderUpload,
  MdOutlineEditNote,
} from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/company_roles_responsibilities_listOfProcedures?documentType=clause10ListOfProcedure',
    isLink: true,
    icon: <MdDriveFolderUpload size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PROCEDURE',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause10&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/clauseUploadList?documentType=listOfPokaYokeClause10&heading=LIST OF POKA-YOKE',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'LIST OF',
  //   span: 'POKA-YOKE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/clauseUploadList?documentType=crossCheckingOfPokaYokeClause10&heading=CROSS CHECKING OF POKA-YOKE',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'CROSS CHECKING OF',
  //   span: 'POKA-YOKE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/clauseUploadList?documentType=pokaYokeFailureClause10&heading=POKA-YOKE FAILURE ANALYSIS',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'POKA-YOKE FAILURE',
  //   span: 'ANALYSIS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 5,
  //   link: '/common/clauseUploadList?documentType=limitSampleAvailabilityClause10&heading=LIMIT SAMPLE AVAILABILITY',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'LIMIT SAMPLE',
  //   span: 'AVAILABILITY',
  //   isIcon: true,
  // },
  // {
  //   sNo: 6,
  //   link: '/common/clauseUploadList?documentType=visualDisplayOfStageClause10&heading=VISUAL DISPLAY OF OK AND NG AT WIP/FG STAGE',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'VISUAL DISPLAY OF OK AND',
  //   span: 'NG AT WIP/FG STAGE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/clauseUploadList?documentType=pokaYokeFailureClause10&heading=POKA-YOKE FAILURE ANALYSIS',
  //   isLink: true,
  //   clauseNo: 10,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'POKA-YOKE FAILURE',
  //   span: 'ANALYSIS',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/admin/masterlistofmachine?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Machine',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistofTools?filter=tools_under_maintenance',
    isLink: true,
    clauseNo: 4,
    icon: <GoTools size="5rem" color="#343a40" />,
    p: 'Master List Of',
    span: 'Tools',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterListOfCriticalSparePart?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Critical Spare Parts (Machine)',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/masterListOfCriticalSparePartStore?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Critical Spare Parts (Store)',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/manMachineMatrixList?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <FaUserCog size="5rem" color="#135089" />,
    p: 'Man Machine',
    span: 'Matrix',
    isIcon: true,
  },
  {
    sNo: 20,
    link: '/common/prevantiveMaintenanceTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" />,
    p: 'Preventive Maintenance',
    span: 'Plan (old)',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/prevantiveMaintenance_NEW_Tiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#a12222" />,
    p: 'Preventive Maintenance',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/maintenanceLog',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Maintenance',
    span: 'Log',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/machineBreakdownRecord',
    isLink: true,
    clauseNo: 4,
    icon: <CgArrowsBreakeV size="5rem" color="#a12222" />,
    p: 'Machine / Breakdown',
    span: 'Record',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/toolBreakdownRecord',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlineFundView size="5rem" color="#a12222" />,
    p: 'Tool Breakdown',
    span: 'Record',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/listOfPokaYoke',
    isLink: true,

    icon: <MdOutlineEditNote size="5rem" color="#003566" />,
    p: 'List of Poka Yoke',
    span: '',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/pokeyokeChecksheet',
    isLink: true,
    icon: <MdChecklistRtl size="5rem" color="#a12222" />,
    p: 'Poke Yoke',
    span: 'CHECK SHEET',
    isIcon: true,
  },
];

export default tiles;
