export const apiMapping = {
    0: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop1',
    1: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop2',
    2: '/api/oeeManagementRoute/overallDayWiseOEEforDifferentPressShops/pressShop3',
    3: '/api/fourMChangeDashboardGraphRoute/changesForGraph/all',
    4: '/api/pendingReportsRoute/pending-reports-for-graph',
    //5: '/api/fourMChangeDashboardGraphRoute/changesForGraph/material',
    //6: '/api/fourMChangeDashboardGraphRoute/changesForGraph/method',
    7: '/api/graphMonthlyMachineBreakdownRoute/machineBreakdownGraphDataMonthly',
    8: '/api/graphMonthlyToolBreakdownRoute/toolBreakdownGraphDataMonthly',
    9: '/api/graphMonthlyMinorBreakdownRoute/getMinorBreakdownsForDifferentShops/pressShop1',
    10: '/api/graphMonthlyMinorBreakdownRoute/getMinorBreakdownsForDifferentShops/pressShop2',
    11: '/api/graphMonthlyMinorBreakdownRoute/getMinorBreakdownsForDifferentShops/pressShop3',
    //12: '/api/pendingReportsRoute/pending-reports-for-graph',
  };