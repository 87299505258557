import React, { useEffect, useState } from 'react';
import TController from './components/combinedView/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash, { forEach } from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import { processes } from './helpers/initialState';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import { PersitedDatePicker } from '../../../components/common';
import {CustomPagination} from '../../../components/common';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Grid } from '@mui/material';


const CombinedView = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState)
  );
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [render, setRender] = useState(false);
  const [partIdx, setPartIdx] = useState(false);
  const [machines, setMachines] = useState([]);
  const [operators, setOperators] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const documentType = useQuery('documentType') || '';
  const [autoCompleteMachineValue, setAutoCompleteMachineValue] = useState<any>(
    Array.from({ length: 5 }, () => lodash.cloneDeep({}))
  );
  const date = useQuery('date');
  const page = useQuery('page') || 1;
  const heading = useQuery('heading') || '';

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogSummary, confirmSummary] = useConfirm(
    'Submit',
    'Are you sure you want to add this to 4M summary?'
  );

  useEffect(() => {
    fetchMachines();
    fetchOperators();
  }, []);

  useEffect(() => {
    fetchData();
  }, [date, documentType, page, selectedMachine, selectedOperator]);

  const fetchMachines = async () => {
    try {
      const response = await axios.get('/api/machineRoutes/machine/ForAutocomplete');
      setMachines(response.data);
    } catch (error) {
      console.error('Error fetching machines:', error);
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await axios.get('/api/employee/operatorList/ForAutoComplete');
      setOperators(response.data);
    } catch (error) {
      console.error('Error fetching operators:', error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/dailyProduction/combinedTable`, {
        params: {
          date,
          documentType,
          page,
          machineId: selectedMachine,
          operator: selectedOperator
        }
      });
      setModuleData(response.data);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
      const machine = response.data.processes.map(
        (process: any) => process.mc_no || {}
      );
      setAutoCompleteMachineValue(machine);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (newPage: number) => {
    navigate(`?date=${date}&documentType=${documentType}&page=${newPage}&machineId=${selectedMachine}&operator=${selectedOperator}`);
  };

  const handleMachineChange = (event: SelectChangeEvent<string>) => {
    setSelectedMachine(event.target.value);
  };

  const handleOperatorChange = (event: SelectChangeEvent<string>) => {
    setSelectedOperator(event.target.value);
  };


  const handleSumbit = async (isSubmitted: boolean, submitType: string) => {
    if (submitType === 'forDailyProduction') {
      const ans = await confirmSubmit();
      if (!ans) return;
    }
    if (submitType === 'forSummary') {
      const ans = await confirmSummary();
      if (!ans) return;
    }
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    if (id) {
      await axios
        .put(`/api/dailyProduction/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.document_type = documentType;
      await axios
        .post('/api/dailyProduction', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    autoCompleteMachineValue.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box sx={{ margin: 2, minWidth: 300 }}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={1.5}>
            <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="machine-select-label">Select Machine</InputLabel>
              <Select
                labelId="machine-select-label"
                value={selectedMachine}
                onChange={handleMachineChange}
                label="Select Machine"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {machines.map((machine: any) => (
                  <MenuItem key={machine._id} value={machine._id}>
                    {machine.machine_no}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="operator-select-label">Select Operator</InputLabel>
              <Select
                labelId="operator-select-label"
                value={selectedOperator}
                onChange={handleOperatorChange}
                label="Select Operator"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {operators.map((operator: any) => (
                  <MenuItem key={operator._id} value={operator.employee_name}>
                    {operator.employee_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          autoCompleteMachineValue,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
        }}
      />

      <CustomPagination 
        totalPage={totalPages} 
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
            partIdx,
          }}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <DialogSummary />
    </>
  );
};

export default CombinedView;
