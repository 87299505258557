import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DashBoardTable from '../components/Table';
import DatePickerMui from '../../../components/common/DatePickerMui';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '../../../hooks/UseQuery';




const PendingReportsTables: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get('date');
    let initialDate: Dayjs;
    if (dateParam) {
      initialDate = dayjs(decodeURIComponent(dateParam));
    } else {
      initialDate = dayjs();
    }
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialDate);
    const endpoint = useQuery('endpoint')  || 'pendingReportsRoute'
    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);
      };

  return (
    <Box sx={{ mt: 0, p: 2.3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={handleDateChange}
            views={['month', 'year']}
            openTo="month"
            format="MMMM YYYY"
            sx={{
                '& .MuiInputBase-root': {
                    height: '40px', // Reduce the height of the input
                    fontSize: '1.2rem', // Reduce the font size
                },
                '& .MuiInputLabel-root': {
                    fontSize: '1.2rem', // Reduce the label font size
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem', // Reduce the size of the calendar icon
                },
            }}
            slotProps={{
                textField: {
                    size: "small", // Use the small variant of TextField
                },
            }}
          />
        </LocalizationProvider>
      </Box>
      <DashBoardTable
        date={selectedDate ? selectedDate.format('YYYY-MM-DD') : ''}
        endpoint={endpoint}
        frozenColumns={1}
      />
    </Box>
  );
};

export default PendingReportsTables;