import { useState, useEffect } from 'react';
import axios from 'axios';

export const useTargets = () => {
  const [targets, setTargets] = useState<number[]>([]);

  useEffect(() => {
    fetchTargets();
  }, []);

  const fetchTargets = async () => {
    try {
      const response = await axios.get('/api/targetRoute/targets');
      setTargets(response.data);
    } catch (error) {
      console.error('Error fetching targets:', error);
    }
  };

  const updateTarget = async (index: number, newValue: number) => {
    const newTargets = [...targets];
    newTargets[index] = newValue;
    setTargets(newTargets);

    try {
      await axios.post('/api/targetRoute/updateTargets', { targets: newTargets });
    } catch (error) {
      console.error('Error updating targets:', error);
    }
  };

  return { targets, updateTarget };
};