import moment from 'moment';
import { useState } from 'react';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={15}
        // colSpan={23}
        rowSpan={3}
        labels={[]}
        values={[]}
        heading="PROCESS & PRODUCT AUDIT REPORT"
      />
      <tr>
        <th align="left">Date:</th>
        <th align="left"
         colSpan={14}
        //  colSpan={22}
         >
          {isView ? (
            formatDate(moduleData.report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.report_date)}
              onChange={(date) => {
                moduleData.report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
