import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FilterState } from './OEEContext';

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
  marginBottom: theme.spacing(2),
  backgroundColor: '#e6f2ff', // Matching the sidebar background
  border: '1px solid #9bcbff'
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#9bcbff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  textTransform: 'none',
  backgroundColor: '#9bcbff',
  color: 'white',
  height: '40px',
  '&:hover': {
    backgroundColor: '#4386cf',
  },
  marginTop: theme.spacing(2),
}));

interface FilterCardProps {
  filter: FilterState;
  onUpdate: (updates: Partial<FilterState>) => void;
  onRemove: () => void;
  title: string;
  onDataTypeClick: () => void;
}

const FilterCard: React.FC<FilterCardProps> = ({ filter, onUpdate, onRemove, title, onDataTypeClick }) => {
  return (
    <StyledCard>
      <CardHeader>``
        <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold' }}>{title}</Typography>
        <IconButton onClick={onRemove} size="small" >
          <CloseIcon  style={{color:'#ffffff'}}/>
        </IconButton>
      </CardHeader>
      <CardContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            value={filter.selectedDate}
            onChange={(newDate:any) => onUpdate({ selectedDate: newDate })}
            views={['year', 'month']}
            sx={{
              width: '100%',
              marginBottom: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
        </LocalizationProvider>
        <StyledButton fullWidth onClick={onDataTypeClick}>
          DATA TYPE: {filter.dataType.toUpperCase()}
        </StyledButton>
      </CardContent>
    </StyledCard>
  );
};
export default FilterCard;