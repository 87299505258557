import React, { useEffect, useRef, useState } from 'react';
import { Stack, TableBody, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import {
  CustomPagination,
  // StatePagination,
  TableCustom,
} from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  AddIconButton,
  CustomIconButton,
  CardIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';

import { IData } from './Interface';
import { Loader } from '../../components/common/Loader';
import ViewFile from './ViewFile';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { useNavigate } from 'react-router-dom';
import SparePartList from './component/list/SparePartList';
import { Add } from '@mui/icons-material';
import ViewSparePartList from './component/list/ViewSparePartList';
import { FaPencilAlt } from 'react-icons/fa';

import formatDate from '../../components/common/formatDate';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../components/ui/module.styled';
import ReportFormat from '../../components/ui/ReportFormat';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  // minWidth: '80px',
  border: '1px solid #343a40',
};

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Machine Name', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'ID No.', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Mfg. Date', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Make', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Capacity', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Department', rowspan: 1, colspan: 1, style: { ...style1 } },
    // { text: 'Last Maintenance Done Date', rowspan: 1, colspan: 1, style: { ...style1 } },
    // { text: 'Maintenance Due Date', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Remarks', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Machine Checklist', rowspan: 1, colspan: 1, style: { ...style1 } },

    // { text: 'Spare Parts Action ', rowspan: 1, colspan: 1, style: { ...style1 } },
    {
      text: 'Machine History Card',
      rowspan: 1,
      colspan: 1,
      style: { ...style1 },
    },
    {
      text: 'Machine Maintenance Card',
      rowspan: 1,
      colspan: 1,
      style: { ...style1 },
    },

    { text: 'Action', rowspan: 1, colspan: 1, style: { ...style1 } },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [machineId, setMachineId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const search = useQuery('search');
  const filter = useQuery('filter');
  const department = useQuery('department');
  const page = useQuery('page') || 1;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [filterDepartment, setFilterDepartment] = useState<any[]>([]);
  const idRef = useRef('');
  const navigate = useNavigate();
  // const [isModalForAddSpare, setisModalForAddSpare] = useState(false);
  // const moduleIdref = useRef<any>(null);
  // const [sparePartIds, setSparePartIds] = useState<string[]>([]);
  // const [isModalForViewSpare, setisModalForViewSpare] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?',
  );

  const encodedDepartment = encodeURIComponent(
    department === 'Press Shop 1 & 2' ? 'Press Shop 1 & 2' : department || ''
  );
  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.results.map((department: any) => ({
            name: department.department_name,
            value: department.department_name,
          }));
          setFilterDepartment([{ name: 'None', value: '' }, ...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/machineRoutes?page=${page}&department=${encodedDepartment}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    console.log(res);
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/machineRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, department, render, isModal]);
  const uploadFile = async (e: any, id: string) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/machineRoutes/uploadFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // const handleUpdateSparePart = (id: string) => {
  //   const sparePartIndex = sparePartIds.indexOf(id); // checking spare part id exists or not , if not exist then push if not then there is only one option that is to remove id
  //   if (sparePartIndex === -1) {
  //     sparePartIds.push(id);
  //   } else {
  //     sparePartIds.splice(sparePartIndex, 1);
  //   }
  //   if (moduleIdref && Array.isArray(sparePartIds)) {
  //     axios
  //       .patch(
  //         `/api/machineRoutes/sparePartIds/${moduleIdref.current}`,
  //         sparePartIds
  //       )
  //       .then((res) => {
  //         if (res.data) {
  //           alert('updated successfull');
  //           setRender((prev) => !prev);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };

  return (
    <>
      <ChildHeader text="MACHINE LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Machine" />
      </ChildHeader>
      <ReportFormat text1={'Format No.: FRM-MNT-A-001'} />

      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 130 }}
            name="department"
            label="Filter By Department"
            options={filterDepartment}
          />
          <FilterBy label="Sort" sx={{ width: 130 }} options={filterOptions} />
        </Stack>
      </SearchFilterContainer>
      <Loader loader={isLoading} />
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <StyledTableRow
                  index={index}
                  // style={{
                  //   background: item.type === 'CRITICAL' ? 'yellow' : undefined,
                  // }}
                  style={{
                    backgroundImage:
                      item.maintenance_card_maintenance_done === 'pending'
                        ? 'linear-gradient(to left, #ff6b6b, #ff6b6b)'
                        : '',
                  }}
                >
                  <StyledTableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.machine_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.machine_no}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {formatDate(item?.mfg_date)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item?.make}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.capacity}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.department_id &&
                      typeof item?.department_id !== 'string' &&
                      item.department_id.department_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.remarks}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack
                      direction={'row'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CustomUploadButton
                        id="upload-vendor-documents"
                        accept="image/png,image/jpeg,image/jpg"
                        multiple={true}
                        onChange={(e) => {
                          uploadFile(e, item?._id || '');
                        }}
                      />
                      {item.files?.length > 0 && (
                        <>
                          <ViewIconButton
                            tooltipTitle="view"
                            sx={{ marginLeft: '1.5rem' }}
                            onClick={() => {
                              idRef.current = item._id || '';
                              setIsOpenPopup(true);
                            }}
                          />
                        </>
                      )}
                    </Stack>
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    {item?.last_maintenance_date}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.maintenance_due_date}
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center">
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CustomIconButton
                        tooltipTitle="add spare parts"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setSparePartIds(item.spare_part_ids || []);
                          setisModalForAddSpare(true);
                        }}
                      >
                        <Add />
                      </CustomIconButton>
                      <ViewIconButton
                        tooltipTitle="view spare parts"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setisModalForViewSpare(true);
                        }}
                      />
                    </Stack>
                  </StyledTableCell> */}
                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CardIconButton
                        tooltipTitle="Machine History"
                        onClick={() => {
                          navigate(`/admin/machineHistory/${item._id}`);
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CardIconButton
                      tooltipTitle="Machine Maintenance History Card"
                      onClick={() => {
                        navigate(
                          `/common/machineMaintenanceHistory/${item._id}`
                        );
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setMachineId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setMachineId(null);
          setIsModal(false);
        }}
        title="CREATE NEW MACHINE"
      >
        <Create id={machineId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} />
      </ModalCustom>
      {/* <ModalCustom
        title="CRITICAL SPARE PART LIST"
        openModal={isModalForAddSpare}
        closeModal={() => {
          setisModalForAddSpare(false);
        }}
      >
        <SparePartList
          type="choose"
          {...{ handleUpdateSparePart, sparePartIds }}
        />
      </ModalCustom> */}
      {/* <ModalCustom
        title="CRITICAL SPARE PART LIST"
        openModal={isModalForViewSpare}
        closeModal={() => {
          setisModalForViewSpare(false);
        }}
      >
        <ViewSparePartList
          moduleId={moduleIdref.current}
          // closeModal={setisModalForViewSpare}
        />
      </ModalCustom> */}
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
