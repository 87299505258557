import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DatePicker from '../components/dateRelated/DatePicker';
import Carousel from '../components/graphPageRelated/Carousel';
import { useGraphData } from '../helpers/useGraphData';
import { useTargets } from '../helpers/useTargets';
import { endpointMapping } from '../helpers/endpointMapping';
import '../styles/Dashboard.css';
import Loader from '../components/graphPageRelated/Loader';

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 50px',
    backgroundColor: '#f8fbff',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  });
  
  const DashboardTitle = styled(Typography)({
    color: '#343a40',
    fontWeight: 'bold',
  });

const DashboardPage: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const navigate = useNavigate();
    const { graphData, isLoading, error, refreshData } = useGraphData(selectedDate);
    const { targets, updateTarget } = useTargets();

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const handleAnalyze = (endpoint: string, title: string, target: number) => {
    const route = endpointMapping[endpoint]?.firstLevelFrontendPage;
    const monthYearRouting = endpointMapping[endpoint]?.monthYearRouting;
    if(monthYearRouting){
      const monthYearDateField = endpointMapping[endpoint]?.monthYearDateField
      const monthYearDate = selectedDate.format('MMM-YYYY').toUpperCase();
      navigate(`${route}?${monthYearDateField}=${monthYearDate}`);
      return;
    }
    if (route) {
      navigate(`${route}?date=${selectedDate}&endpoint=${endpoint}&title=${encodeURIComponent(title)}&target=${target}`);
    }else{
      console.log(graphData)
    }
  };

  //if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="dashboard">
      <HeaderContainer>
        <DashboardTitle variant="h6">DASHBOARD</DashboardTitle>
        <DatePicker selectedDate={selectedDate} onChange={handleDateChange} />
      </HeaderContainer>
      <Carousel
        graphData={graphData}
        targets={targets}
        updateTarget={updateTarget}
        onAnalyze={handleAnalyze}
      />
    </div>
  );
};

export default DashboardPage;