import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

interface Machine {
  _id: string;
  machine_name: string;
  machine_no: string;
}

interface Operator {
  _id: string;
  employee_name: string;
  department_id: {
    department_name: string;
  };
  employee_type: string;
}

interface DataTypeSelectorProps {
  dataType: 'overall' | 'machine' | 'operator';
  onDataTypeChange: (type: 'overall' | 'machine' | 'operator') => void;
  selectedValue: string | null;
  onValueChange: (value: string | null) => void;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  '& .MuiInputLabel-root': {
    transform: 'translate(12px, 8px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, -9px) scale(0.75)',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: 'white',
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const DataTypeSelector: React.FC<DataTypeSelectorProps> = ({
  dataType,
  onDataTypeChange,
  selectedValue,
  onValueChange
}) => {
  console.log('DataTypeSelector rendered with:', { dataType, selectedValue });

  const [machines, setMachines] = useState<Machine[]>([]);
  const [operators, setOperators] = useState<Operator[]>([]);

  useEffect(() => {
    if (dataType === 'machine') {
      fetchMachines();
    } else if (dataType === 'operator') {
      fetchOperators();
    }
  }, [dataType]);

  const fetchMachines = async () => {
    try {
      const response = await axios.get('/api/machineRoutes/machine/ForAutocomplete');
      setMachines(response.data);
    } catch (error) {
      console.error('Error fetching machines:', error);
    }
  };

  const fetchOperators = async () => {
    try {
      const response = await axios.get('/api/employee/operatorList/ForAutoComplete');
      setOperators(response.data);
    } catch (error) {
      console.error('Error fetching operators:', error);
    }
  };

  return (
    <Box>
      <StyledFormControl>
        <InputLabel>Data Type</InputLabel>
        <Select
          value={dataType}
          onChange={(e) => {
            console.log('Data type changed to:', e.target.value);
            onDataTypeChange(e.target.value as 'overall' | 'machine' | 'operator');
            onValueChange(null);
          }}
          label="Data Type"
        >
          <MenuItem value="overall">Overall OEE</MenuItem>
          <MenuItem value="machine">Machine</MenuItem>
          <MenuItem value="operator">Operator</MenuItem>
        </Select>
      </StyledFormControl>
      {dataType !== 'overall' && (
        <StyledFormControl>
          <InputLabel>{dataType === 'machine' ? 'Machine' : 'Operator'}</InputLabel>
          <Select
            value={selectedValue || ''}
            onChange={(e) => onValueChange(e.target.value)}
            label={dataType === 'machine' ? 'Machine' : 'Operator'}
          >
            {dataType === 'machine'
              ? machines.map((machine) => (
                  <MenuItem key={machine._id} value={machine._id}>
                    {`${machine.machine_name} (${machine.machine_no})`}
                  </MenuItem>
                ))
              : operators.map((operator) => (
                  <MenuItem key={operator._id} value={operator.employee_name}>
                    {`${operator.employee_name} - ${operator.department_id.department_name} (${operator.employee_type})`}
                  </MenuItem>
                ))
            }
          </Select>
        </StyledFormControl>
      )}
    </Box>
  );
};

export default DataTypeSelector;






//////////////////////// sanity ////////////////////////////
/////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////
//////////
////////////////////
////////////////////////////////