import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';

interface CycleTimeInputs {
  [key: string]: string;
}

const useDebouncedCycleTime = (delay: number = 100) => {
  const [cycleTimeInputs, setCycleTimeInputs] = useState<CycleTimeInputs>({});
  const [isEditingCycleTimes, setIsEditingCycleTimes] = useState(false);
  const debouncedUpdateRef = useRef<{[key: string]: NodeJS.Timeout}>({});

  const updateCycleTime = useCallback(async (id: string, cycleTime: number) => {
    try {
      await axios.patch(`/api/masterListOfTools/cycleTime/${id}`, { cycle_time: cycleTime });
      // Note: We're not calling setRender here as it's specific to the List component
    } catch (err) {
      console.error("Error updating cycle time:", err);
    }
  }, []);

  const handleCycleTimeChange = (id: string, value: string) => {
    setCycleTimeInputs(prev => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    const debouncedUpdate = (id: string, value: string) => {
      clearTimeout(debouncedUpdateRef.current[id]);
      debouncedUpdateRef.current[id] = setTimeout(() => {
        const cycleTime = parseFloat(value);
        if (!isNaN(cycleTime)) {
          updateCycleTime(id, cycleTime);
        }
      }, delay);
    };

    Object.entries(cycleTimeInputs).forEach(([id, value]) => {
      debouncedUpdate(id, value);
    });

    return () => {
      Object.values(debouncedUpdateRef.current).forEach(clearTimeout);
    };
  }, [cycleTimeInputs, updateCycleTime, delay]);

  return {
    cycleTimeInputs,
    handleCycleTimeChange,
    isEditingCycleTimes,
    setIsEditingCycleTimes
  };
};

export default useDebouncedCycleTime;