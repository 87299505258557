import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
// import { RiDeleteBin4Fill } from 'react-icons/ri';
import { TbFileReport } from 'react-icons/tb';
import { MdReceiptLong } from 'react-icons/md';
import { FiAlertCircle } from "react-icons/fi";

const tiles = [
  {
    sNo: 1,
    link: '/common/rotorSection/dailyProductionList?documentType=pressShop1&heading=Press Shop 1',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'PRESS SHOP',
    span: '1 & 2',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/rotorSection/dailyProductionList?documentType=pressShop2&heading=Press Shop 2',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'PRESS SHOP',
    span: '3',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/rotorSection/dailyProductionList?documentType=pressShop3&heading=Press Shop 3',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'WELD ',
    span: 'SHOP',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/breakDownSlipList',
    isLink: true,
    icon: <MdReceiptLong size="5rem" color="#a12222" />,
    p: 'BREAK DOWN',
    span: 'SLIP',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/downTimeReasons',
    isLink: true,
    icon: <FiAlertCircle size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Downtime Reasons',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/rotorSection',
  //   isLink: true,
  //   icon: <HiDocumentReport size="5rem" color="#a12222" />,
  //   p: 'ROTOR',
  //   span: 'Section',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/pressShop',
  //   isLink: true,
  //   icon: <TbReportAnalytics size="5rem" color="#a12222" />,
  //   p: 'Press',
  //   span: 'Shop',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/pdc',
  //   isLink: true,
  //   icon: <TbReport size="5rem" color="#a12222" />,
  //   p: 'PDC',
  //   span: 'Section',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/cnc',
  //   isLink: true,
  //   icon: <TbFileReport size="5rem" color="#a12222" />,
  //   p: 'CNC',
  //   span: 'Section',
  //   isIcon: true,
  // },
];

export default tiles;
